/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiMapPin = ({ className }) => {
  return (
    <svg
      className={`li-map-pin ${className}`}
      fill="none"
      height="9"
      viewBox="0 0 10 9"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_435_714)">
        <path
          className="path"
          clipRule="evenodd"
          d="M4.98933 1.13559C4.23729 1.13559 3.51606 1.43433 2.98429 1.9661C2.45252 2.49787 2.15377 3.21911 2.15377 3.97114C2.15377 5.06923 2.86824 6.13476 3.65164 6.96168C4.03609 7.36749 4.42175 7.69971 4.71169 7.93059C4.81959 8.01651 4.91384 8.08808 4.98933 8.14387C5.06482 8.08808 5.15907 8.01651 5.26697 7.93059C5.55691 7.69971 5.94257 7.36749 6.32702 6.96168C7.11042 6.13476 7.82489 5.06923 7.82489 3.97114C7.82489 3.21911 7.52614 2.49787 6.99437 1.9661C6.4626 1.43433 5.74137 1.13559 4.98933 1.13559ZM4.98933 8.57892C4.79272 8.87383 4.79263 8.87377 4.79253 8.87371L4.79151 8.87303L4.78915 8.87144L4.78103 8.86595C4.77413 8.86126 4.76428 8.85453 4.7517 8.84581C4.72652 8.82838 4.69034 8.80297 4.64474 8.77003C4.55356 8.70418 4.42447 8.60806 4.2701 8.48514C3.96191 8.23973 3.55007 7.88521 3.13702 7.44921C2.32542 6.59252 1.44489 5.35416 1.44489 3.97114C1.44489 3.0311 1.81832 2.12955 2.48303 1.46484C3.14774 0.800128 4.04928 0.426697 4.98933 0.426697C5.92937 0.426697 6.83092 0.800128 7.49563 1.46484C8.16034 2.12955 8.53377 3.0311 8.53377 3.97114C8.53377 5.35416 7.65324 6.59252 6.84164 7.44921C6.42859 7.88521 6.01675 8.23973 5.70856 8.48514C5.55419 8.60806 5.4251 8.70418 5.33392 8.77003C5.28832 8.80297 5.25214 8.82838 5.22696 8.84581C5.21438 8.85453 5.20453 8.86126 5.19763 8.86595L5.18951 8.87144L5.18714 8.87303L5.1864 8.87353C5.18629 8.8736 5.18594 8.87383 4.98933 8.57892ZM4.98933 8.57892L5.18594 8.87383C5.06688 8.95321 4.91159 8.95308 4.79253 8.87371L4.98933 8.57892Z"
          fill="#3A3555"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M4.98934 3.26226C4.59783 3.26226 4.28045 3.57964 4.28045 3.97115C4.28045 4.36266 4.59783 4.68004 4.98934 4.68004C5.38085 4.68004 5.69823 4.36266 5.69823 3.97115C5.69823 3.57964 5.38085 3.26226 4.98934 3.26226ZM3.57156 3.97115C3.57156 3.18814 4.20632 2.55338 4.98934 2.55338C5.77236 2.55338 6.40712 3.18814 6.40712 3.97115C6.40712 4.75417 5.77236 5.38893 4.98934 5.38893C4.20632 5.38893 3.57156 4.75417 3.57156 3.97115Z"
          fill="#3A3555"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_435_714">
          <rect
            className="rect"
            fill="white"
            height="8.50667"
            transform="translate(0.735992 0.426697)"
            width="8.50667"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
