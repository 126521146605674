/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiCompass = ({ className }) => {
  return (
    <svg
      className={`li-compass ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_429_323)">
        <path
          className="path"
          clipRule="evenodd"
          d="M9.32912 4.37747C5.88037 5.27111 3.80905 8.7913 4.70269 12.2401C5.59633 15.6888 9.11653 17.7601 12.5653 16.8665C16.014 15.9728 18.0853 12.4526 17.1917 9.00389C16.2981 5.55515 12.7779 3.48383 9.32912 4.37747ZM3.31502 12.5996C2.2228 8.38449 4.75441 4.08202 8.96955 2.9898C13.1847 1.89757 17.4871 4.42919 18.5794 8.64432C19.6716 12.8595 17.14 17.1619 12.9248 18.2541C8.70971 19.3464 4.40725 16.8148 3.31502 12.5996Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M13.4902 6.30009C13.7242 6.43776 13.8603 6.69582 13.8418 6.96668L13.5143 11.7606C13.499 11.9857 13.3785 12.1904 13.1892 12.3131L9.15759 14.9275C8.9298 15.0752 8.63812 15.0816 8.40413 14.9439C8.17015 14.8062 8.03403 14.5481 8.05254 14.2773L8.38002 9.48335C8.3954 9.25827 8.51585 9.05357 8.70513 8.93082L12.7368 6.31645C12.9646 6.16874 13.2562 6.16241 13.4902 6.30009ZM9.78576 9.93859L9.5804 12.9448L12.1086 11.3054L12.314 8.29914L9.78576 9.93859Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_429_323">
          <rect
            className="rect"
            fill="white"
            height="17.202"
            transform="translate(0.463745 4.4534) rotate(-14.527)"
            width="17.202"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
