/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiBell = ({ className }) => {
  return (
    <svg
      className={`li-bell ${className}`}
      fill="none"
      height="11"
      viewBox="0 0 11 11"
      width="11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_448_1570)">
        <path
          className="path"
          clipRule="evenodd"
          d="M2.98016 2.68124C3.30126 2.13554 3.82598 1.73975 4.43889 1.58093C5.0518 1.42211 5.7027 1.51328 6.24839 1.83437C6.79409 2.15547 7.18988 2.68018 7.3487 3.2931C7.63551 4.39995 8.05034 5.02595 8.37215 5.36939C8.53373 5.54183 8.67453 5.64593 8.76889 5.70494C8.81618 5.73451 8.85209 5.75294 8.87345 5.76303C8.88414 5.76808 8.8912 5.77104 8.89423 5.77228C8.8947 5.77247 8.89508 5.77262 8.89535 5.77273C9.03477 5.82336 9.12568 5.9585 9.11966 6.10707C9.11358 6.25723 9.00993 6.38572 8.86444 6.42341L2.9219 7.96325C2.77642 8.00094 2.62341 7.93897 2.54517 7.81065C2.46776 7.6837 2.4816 7.52142 2.57889 7.40945C2.57908 7.40922 2.57933 7.40891 2.57965 7.40851C2.5817 7.40596 2.58643 7.39994 2.59332 7.39034C2.6071 7.37114 2.62954 7.33759 2.65652 7.28878C2.71035 7.19137 2.78287 7.03199 2.84038 6.80278C2.9549 6.34628 3.01353 5.59759 2.72672 4.49074C2.56791 3.87783 2.65907 3.22693 2.98016 2.68124ZM2.58164 7.40632C2.58166 7.4063 2.58168 7.40628 2.5817 7.40625C2.5817 7.40625 2.5817 7.40625 2.5817 7.40626L2.58164 7.40632ZM3.46039 7.1191L7.98377 5.947C7.94799 5.91235 7.91148 5.87532 7.87442 5.83577C7.46576 5.39964 7.00043 4.66833 6.68842 3.46419C6.57498 3.02639 6.29227 2.6516 5.90248 2.42224C5.5127 2.19289 5.04777 2.12777 4.60998 2.24121C4.17219 2.35466 3.79739 2.63736 3.56803 3.02715C3.33868 3.41693 3.27356 3.88186 3.38701 4.31965C3.69902 5.52379 3.6474 6.38906 3.50196 6.96876C3.48878 7.02133 3.47485 7.07143 3.46039 7.1191Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M5.33907 8.08909C5.47308 7.95673 5.68901 7.95807 5.82137 8.09208C5.86335 8.13459 5.91577 8.16532 5.97337 8.1812C6.03096 8.19708 6.09172 8.19755 6.14956 8.18257C6.2074 8.16758 6.26028 8.13766 6.30292 8.09581C6.34556 8.05395 6.37645 8.00163 6.39251 7.94408C6.44313 7.76266 6.63124 7.65662 6.81267 7.70725C6.99409 7.75787 7.10013 7.94598 7.0495 8.1274C7.00133 8.30005 6.90865 8.45701 6.78073 8.58257C6.65282 8.70814 6.49416 8.79789 6.32065 8.84285C6.14714 8.88781 5.96486 8.8864 5.79207 8.83876C5.61927 8.79111 5.46203 8.69891 5.33607 8.57139C5.20372 8.43737 5.20506 8.22144 5.33907 8.08909Z"
          fill="#636073"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_448_1570">
          <rect
            className="rect"
            fill="white"
            height="8.18507"
            transform="translate(0.391724 2.27734) rotate(-14.527)"
            width="8.18507"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
