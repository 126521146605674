import React from "react";
import { IconChevronsUpDown } from "../../icons/IconChevronsUpDown";
import { IconMusic } from "../../icons/IconMusic";
import { IconQrCode } from "../../icons/IconQrCode";
import { LiBell } from "../../icons/LiBell";
import { LiBell1 } from "../../icons/LiBell1";
import { LiCalendar } from "../../icons/LiCalendar";
import { LiCalendar3 } from "../../icons/LiCalendar3";
import { LiChevronLeft } from "../../icons/LiChevronLeft";
import { LiClock } from "../../icons/LiClock";
import { LiCompass } from "../../icons/LiCompass";
import { LiCompass1 } from "../../icons/LiCompass1";
import { LiMapPin } from "../../icons/LiMapPin";
import { LiMapPin3 } from "../../icons/LiMapPin3";
import { LiQrCodeReady } from "../../icons/LiQrCodeReady";
import { LiSend1 } from "../../icons/LiSend1";
import { LiSend2 } from "../../icons/LiSend2";
import { LiTicket } from "../../icons/LiTicket";
import { LiTicket1 } from "../../icons/LiTicket1";
import { LiUser } from "../../icons/LiUser";
import { LiUser1 } from "../../icons/LiUser1";
import "./mobile-style.css";
import { Link } from "react-router-dom";
import { RoutePaths } from "../../../../App";
import { appStoreUrl, chPlayUrl } from "../../../../common/constans";

export const MobileView = () => {
  return (
    <div className="frame-mobile">
      <div className="div">
        <div className="overlap">
          <div className="rectangle" />
          <p className="s-n-ph-m-c-a-c-ng-ty">
            Sản phẩm của
            <br />
            Công ty TNHH Giải pháp Công nghệ Ubiz
          </p>
          <div className="text-wrapper">Email: hi@veve.com.vn</div>
          <img className="vector" alt="Vector" src="/img/vector-2.svg" />
          <div className="overlap-wrapper">
            <div className="overlap-group">
              <div className="rectangle-2" />
              <div className="ellipse" />
              <p className="vev-ngay-th-i-ch-chi">
                Vevé ngay thôi!
                <br />
                Chờ chi nữa
              </p>
              <img
                className="appstore"
                alt="Appstore"
                src="/img/appstore.png"
                onClick={() => {
                  window.open(appStoreUrl, "_blank");
                }}
              />
              <img
                className="googleplay"
                alt="Googleplay"
                src="/img/googleplay.png"
                onClick={() => {
                  window.open(chPlayUrl, "_blank");
                }}
              />
            </div>
          </div>
          <div className="div-2">
            <div className="text-wrapper-2">
              <Link to={RoutePaths.termsOfService}>Điều Khoản Sử Dụng</Link>
            </div>
            <div className="text-wrapper-2">
              <Link to={RoutePaths.privacyPolicy}>Chính Sách Bảo Mật</Link>
            </div>
            <div className="text-wrapper-2">
              <Link to={RoutePaths.refundPolicy}>Chính Sách Hoàn Trả</Link>
            </div>
            <div className="text-wrapper-2">
              <Link to={RoutePaths.disputeSettlementPolicy}>
                Giải Quyết Tranh Chấp
              </Link>
            </div>
          </div>
        </div>
        <div className="overlap-2">
          <div className="ellipse-2" />
          <div className="ellipse-3" />
          <div className="iphone-mini">
            <div className="div-3">
              <div className="div-4">
                <LiCompass1 className="icon-instance-node" />
                <div className="text-wrapper-3">Khám phá</div>
              </div>
              <div className="div-5">
                <LiTicket1 className="icon-instance-node" />
                <div className="text-wrapper-4">Vé của tôi</div>
              </div>
              <div className="div-5">
                <LiBell1 className="icon-instance-node" />
                <div className="text-wrapper-3">Thông báo</div>
              </div>
              <div className="div-5">
                <LiUser1 className="icon-instance-node" />
                <div className="text-wrapper-3">Tôi</div>
              </div>
            </div>
            <div className="text-wrapper-5">Vé của tôi</div>
            <div className="text-wrapper-6">Chủ Nhật, 20/08/2023</div>
            <div className="text-wrapper-7">Chủ Nhật, 27/08/2023</div>
            <div className="div-6">
              <img
                className="img"
                alt="Rectangle"
                src="/img/rectangle-34-6.png"
              />
              <div className="div-7">
                <div className="text-wrapper-8">Acoustic Night: Đến</div>
                <div className="div-8">
                  <div className="div-9">
                    <LiCalendar3 className="icon-instance-node-2" />
                  </div>
                  <p className="text-wrapper-9">
                    20:00 - 23:00 Chủ Nhật, 20/08/2023
                  </p>
                </div>
                <div className="div-8">
                  <div className="div-9">
                    <LiMapPin3 className="icon-instance-node-2" />
                  </div>
                  <p className="text-wrapper-10">
                    Cửa hàng 81 Cafe - 30 Nguyễn Hữu Cầu, P. Tân Định, Quận 1,
                    TP. Hồ Chí Minh
                  </p>
                </div>
              </div>
              <div className="div-wrapper">
                <div className="text-wrapper-11">3 vé</div>
              </div>
              <div className="div-10">
                <LiQrCodeReady className="icon-instance-node-3" />
                <div className="text-wrapper-12">QR sẵn sàng</div>
              </div>
            </div>
            <div className="div-11">
              <img
                className="img"
                alt="Rectangle"
                src="/img/rectangle-34-5.png"
              />
              <div className="div-7">
                <div className="text-wrapper-8">Acoustic Night: Về</div>
                <div className="div-8">
                  <div className="div-9">
                    <LiCalendar3 className="icon-instance-node-2" />
                  </div>
                  <p className="text-wrapper-9">
                    20:00 - 23:00 Chủ Nhật, 20/08/2023
                  </p>
                </div>
                <div className="div-8">
                  <div className="div-9">
                    <LiMapPin3 className="icon-instance-node-2" />
                  </div>
                  <p className="text-wrapper-10">
                    Cửa hàng 81 Cafe - 30 Nguyễn Hữu Cầu, P. Tân Định, Quận 1,
                    TP. Hồ Chí Minh
                  </p>
                </div>
              </div>
              <div className="div-wrapper-2">
                <div className="text-wrapper-11">3 vé</div>
              </div>
              <div className="div-12">
                <LiSend2 className="icon-instance-node-3" />
                <div className="text-wrapper-12">Chuyển nhượng</div>
              </div>
            </div>
            <div className="bars-status-default">
              <div className="action">
                <div className="time">9:41</div>
              </div>
              <div className="container">
                <div className="battery">
                  <div className="overlap-group-2">
                    <img
                      className="rectangle-3"
                      alt="Rectangle"
                      src="/img/rectangle-4.svg"
                    />
                    <div className="rectangle-4" />
                  </div>
                </div>
                <img
                  className="combined-shape"
                  alt="Combined shape"
                  src="/img/combined-shape-4.svg"
                />
                <img className="wi-fi" alt="Wi fi" src="/img/wi-fi-4.svg" />
              </div>
            </div>
            <LiClock className="li-clock" />
          </div>
          <div className="iphone-mini-2">
            <p className="text-wrapper-13">Tp. Hồ Chí Minh - Quận 3</p>
            <div className="div-13">
              <div className="text-wrapper-14">Acoustic</div>
              <div className="div-14">
                <div className="div-15">
                  <IconMusic className="icon-music" />
                  <div className="text-wrapper-15">Âm nhạc</div>
                </div>
                <IconChevronsUpDown className="icon-chevrons-up" />
              </div>
            </div>
            <div className="div-16">
              <img
                className="rectangle-5"
                alt="Rectangle"
                src="/img/rectangle-34-4.png"
              />
              <div className="div-17">
                <div className="text-wrapper-16">Acoustic Night: Đến</div>
                <div className="div-18">
                  <div className="text-wrapper-17">Chủ Nhật, 20/08/2023</div>
                  <div className="text-wrapper-18">Cửa hàng 81 Cafe</div>
                </div>
              </div>
              <div className="div-wrapper-3">
                <div className="text-wrapper-19">từ 30.000 đ</div>
              </div>
            </div>
            <div className="overlap-3">
              <div className="div-19">
                <img
                  className="rectangle-5"
                  alt="Rectangle"
                  src="/img/rectangle-34-3.png"
                />
                <div className="div-17">
                  <div className="text-wrapper-16">Acoustic Night: Về</div>
                  <div className="div-18">
                    <div className="text-wrapper-17">Chủ Nhật, 27/08/2023</div>
                    <div className="text-wrapper-18">Cửa hàng 81 Cafe</div>
                  </div>
                </div>
                <div className="div-wrapper-3">
                  <div className="text-wrapper-19">từ 30.000 đ</div>
                </div>
              </div>
              <div className="div-20">
                <div className="div-21">
                  <LiCompass className="icon-instance-node-4" />
                  <div className="text-wrapper-20">Khám phá</div>
                </div>
                <div className="div-22">
                  <LiTicket className="icon-instance-node-4" />
                  <div className="text-wrapper-21">Vé của tôi</div>
                </div>
                <div className="div-22">
                  <LiBell className="icon-instance-node-4" />
                  <div className="text-wrapper-21">Thông báo</div>
                </div>
                <div className="div-22">
                  <LiUser className="icon-instance-node-4" />
                  <div className="text-wrapper-21">Tôi</div>
                </div>
              </div>
            </div>
            <img className="vector-2" alt="Vector" src="/img/vector-1.svg" />
            <div className="text-wrapper-22">2 kết quả</div>
            <div className="overlap-4">
              <img
                className="img-2"
                alt="frame-mobile"
                src="/img/frame-16.svg"
              />
              <div className="bars-status-default-2">
                <div className="action">
                  <div className="time">9:41</div>
                </div>
                <div className="container-2">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group-3">
                      <img
                        className="img-3"
                        alt="Rectangle"
                        src="/img/rectangle-2.svg"
                      />
                      <div className="rectangle-6" />
                    </div>
                  </div>
                  <img
                    className="combined-shape-2"
                    alt="Combined shape"
                    src="/img/combined-shape-2.svg"
                  />
                  <img className="wi-fi-2" alt="Wi fi" src="/img/wi-fi-2.svg" />
                </div>
              </div>
              <div className="bars-status-default-2">
                <div className="action">
                  <div className="time">9:41</div>
                </div>
                <div className="container-2">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group-3">
                      <img
                        className="img-3"
                        alt="Rectangle"
                        src="/img/rectangle-2.svg"
                      />
                      <div className="rectangle-6" />
                    </div>
                  </div>
                  <img
                    className="combined-shape-2"
                    alt="Combined shape"
                    src="/img/combined-shape-2.svg"
                  />
                  <img className="wi-fi-2" alt="Wi fi" src="/img/wi-fi-2.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-wrapper-23">Vé sự kiện?</div>
        <div className="div-23">
          <div className="text-wrapper-24">Đơn giản là</div>
          <img className="vector-3" alt="Vector" src="/img/vector.svg" />
        </div>
        <div className="div-24">
          <div className="overlap-wrapper-2">
            <div className="overlap-5">
              <div className="ellipse-4" />
              <div className="iphone-mini-3">
                <img
                  className="rectangle-7"
                  alt="Rectangle"
                  src="/img/rectangle-34-2.png"
                />
                <div className="overlap-group-4">
                  <div className="div-25">
                    <div className="div-26">
                      <img
                        className="image"
                        alt="Image"
                        src="/img/image-4-1.png"
                      />
                      <div className="text-wrapper-25">Thanh toán với Momo</div>
                    </div>
                    <div className="text-wrapper-26">Tổng</div>
                  </div>
                  <div className="text-wrapper-27">160.000đ</div>
                </div>
                <div className="text-wrapper-28">Thanh toán</div>
                <div className="div-27">
                  <div className="div-28">
                    <div className="div-29">
                      <div className="text-wrapper-29">Vé tham dự</div>
                      <div className="text-wrapper-30">2</div>
                      <div className="text-wrapper-31">30.000 đ</div>
                    </div>
                    <div className="div-29">
                      <div className="text-wrapper-29">Vé hạng nhất</div>
                      <div className="text-wrapper-30">1</div>
                      <div className="text-wrapper-31">100.000đ</div>
                    </div>
                  </div>
                  <div className="text-wrapper-32">Hạng vé</div>
                  <div className="text-wrapper-33">Số lượng</div>
                  <div className="text-wrapper-34">Giá</div>
                </div>
                <div className="div-30">
                  <div className="text-wrapper-35">Acoustic Night: Đến</div>
                  <div className="div-31">
                    <div className="div-32">
                      <LiCalendar className="icon-instance-node-5" />
                    </div>
                    <p className="text-wrapper-36">
                      20:00 - 23:00 Chủ Nhật, 20/08/2023
                    </p>
                  </div>
                  <div className="div-31">
                    <div className="div-32">
                      <LiMapPin className="icon-instance-node-5" />
                    </div>
                    <p className="text-wrapper-37">
                      Cửa hàng 81 Cafe - 30 Nguyễn Hữu Cầu, P. Tân Định, Quận 1,
                      TP. Hồ Chí Minh
                    </p>
                  </div>
                </div>
                <div className="text-wrapper-38">Đơn hàng</div>
                <LiChevronLeft className="li-chevron-left" color="#3A3555" />
                <div className="text-wrapper-39">Quay lại</div>
                <div className="bars-status-default-3">
                  <div className="time-wrapper">
                    <div className="time-2">9:41</div>
                  </div>
                  <div className="container-3">
                    <div className="rectangle-wrapper">
                      <div className="rectangle-8" />
                    </div>
                    <img
                      className="img-3"
                      alt="Combined shape"
                      src="/img/combined-shape-1.svg"
                    />
                    <img
                      className="wi-fi-3"
                      alt="Wi fi"
                      src="/img/wi-fi-1.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-33">
            <div className="div-34">
              <div className="text-wrapper-40">Mua vé?</div>
              <div className="text-wrapper-41">Đơn giản</div>
            </div>
            <p className="text-wrapper-42">
              Chỉ cần chưa đầy 5 phút, bạn đã có thể hoàn thành quá trình mua vé
              của mình với ví Momo.
            </p>
          </div>
        </div>
        <div className="div-35">
          <div className="div-36">
            <div className="div-34">
              <div className="text-wrapper-40">Bán vé?</div>
              <div className="text-wrapper-41">Dễ dàng</div>
            </div>
            <p className="text-wrapper-42">
              Bạn là nhà tổ chức nghiệp dư? Bạn chưa quen với việc sử dụng các
              nền tảng bán vé? Bạn còn bán vé thủ công qua Forms? Chúng tôi ở
              đây để giúp bạn
            </p>
          </div>
          <div className="overlap-group-wrapper-2">
            <div className="overlap-group-5">
              <div className="ellipse-5" />
              <img
                className="img-4"
                alt="frame-mobile"
                src="/img/frame-1.svg"
              />
              <img
                className="img-5"
                alt="frame-mobile"
                src="/img/frame-2.svg"
              />
            </div>
          </div>
          <div className="div-wrapper-4">
            <div className="text-wrapper-43">Tìm hiểu thêm</div>
          </div>
        </div>
        <div className="div-37">
          <div className="div-38">
            <div className="frame-mobile-wrapper">
              <div className="div-39">
                <div className="text-wrapper-44">Săn sự kiện?</div>
                <div className="text-wrapper-45">Siêu nhàn</div>
              </div>
            </div>
            <p className="text-wrapper-46">
              Nhiều và rất nhiều sự kiện sẽ đổ bộ Vevé, thoả thích săn mãi không
              hết.
            </p>
          </div>
          <div className="overlap-wrapper-3">
            <div className="overlap-5">
              <div className="ellipse-6" />
              <div className="iphone-mini-3">
                <img
                  className="rectangle-7"
                  alt="Rectangle"
                  src="/img/rectangle-34-1.png"
                />
                <div className="overlap-group-4">
                  <div className="div-25">
                    <div className="div-26">
                      <img
                        className="image"
                        alt="Image"
                        src="/img/image-4.png"
                      />
                      <div className="text-wrapper-25">Thanh toán với Momo</div>
                    </div>
                    <div className="text-wrapper-26">Tổng</div>
                  </div>
                  <div className="text-wrapper-27">160.000đ</div>
                </div>
                <div className="text-wrapper-28">Thanh toán</div>
                <div className="div-27">
                  <div className="div-28">
                    <div className="div-29">
                      <div className="text-wrapper-29">Vé tham dự</div>
                      <div className="text-wrapper-30">2</div>
                      <div className="text-wrapper-31">30.000 đ</div>
                    </div>
                    <div className="div-29">
                      <div className="text-wrapper-29">Vé hạng nhất</div>
                      <div className="text-wrapper-30">1</div>
                      <div className="text-wrapper-31">100.000đ</div>
                    </div>
                  </div>
                  <div className="text-wrapper-32">Hạng vé</div>
                  <div className="text-wrapper-33">Số lượng</div>
                  <div className="text-wrapper-34">Giá</div>
                </div>
                <div className="div-30">
                  <div className="text-wrapper-35">Acoustic Night: Đến</div>
                  <div className="div-31">
                    <div className="div-32">
                      <LiCalendar className="icon-instance-node-5" />
                    </div>
                    <p className="text-wrapper-36">
                      20:00 - 23:00 Chủ Nhật, 20/08/2023
                    </p>
                  </div>
                  <div className="div-31">
                    <div className="div-32">
                      <LiMapPin className="icon-instance-node-5" />
                    </div>
                    <p className="text-wrapper-37">
                      Cửa hàng 81 Cafe - 30 Nguyễn Hữu Cầu, P. Tân Định, Quận 1,
                      TP. Hồ Chí Minh
                    </p>
                  </div>
                </div>
                <div className="text-wrapper-38">Đơn hàng</div>
                <LiChevronLeft className="li-chevron-left" color="#3A3555" />
                <div className="text-wrapper-39">Quay lại</div>
                <div className="bars-status-default-3">
                  <div className="time-wrapper">
                    <div className="time-2">9:41</div>
                  </div>
                  <div className="container-3">
                    <div className="battery-2">
                      <div className="rectangle-8" />
                    </div>
                    <img
                      className="img-3"
                      alt="Combined shape"
                      src="/img/combined-shape.svg"
                    />
                    <img className="wi-fi-3" alt="Wi fi" src="/img/wi-fi.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div-40">
          <div className="div-41">
            <div className="div-34">
              <div className="div-42">
                <div className="text-wrapper-44">Pass vé?</div>
                <div className="text-wrapper-45">An toàn</div>
              </div>
            </div>
            <p className="text-wrapper-42">
              Chuyển nhượng vé ngay trên app, không cần email, không lo lừa đảo.
            </p>
          </div>
          <div className="overlap-wrapper-4">
            <div className="overlap-6">
              <div className="ellipse-4" />
              <div className="iphone-mini-4">
                <div className="overlap-group-6">
                  <div className="overlap-7">
                    <div className="text-wrapper-47">Vé tham dự</div>
                    <p className="text-wrapper-48">
                      QR sẽ sẵn sàng trong 18:00:09
                    </p>
                    <IconQrCode className="icon-qr-code" />
                  </div>
                  <div className="div-43">
                    <img
                      className="rectangle-9"
                      alt="Rectangle"
                      src="/img/rectangle-34.png"
                    />
                    <div className="text-wrapper-49">Acoustic Night: Đến</div>
                  </div>
                </div>
              </div>
              <div className="div-44">
                <LiSend1 className="li-send" />
                <div className="text-wrapper-50">Chuyển nhượng</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
