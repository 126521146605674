/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiSend1 = ({ className }) => {
  return (
    <svg
      className={`li-send-1 ${className}`}
      fill="none"
      height="36"
      viewBox="0 0 35 36"
      width="35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M32.502 2.7739C33.0586 3.33051 33.0586 4.23295 32.502 4.78956L16.8238 20.4677C16.2672 21.0243 15.3648 21.0243 14.8082 20.4677C14.2516 19.9111 14.2516 19.0087 14.8082 18.452L30.4863 2.7739C31.0429 2.21729 31.9454 2.21729 32.502 2.7739Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M32.502 2.77394C32.8891 3.1611 33.0203 3.73582 32.8394 4.25261L22.8624 32.7583C22.6694 33.3099 22.1592 33.6878 21.5753 33.7116C20.9913 33.7354 20.4521 33.4004 20.2147 32.8664L14.7362 20.5397L2.40957 15.0612C1.87554 14.8239 1.54051 14.2846 1.56433 13.7007C1.58815 13.1168 1.966 12.6066 2.51759 12.4135L31.0233 2.4365C31.5401 2.25563 32.1148 2.38678 32.502 2.77394ZM6.85249 13.9164L16.3949 18.1575C16.7173 18.3008 16.9751 18.5586 17.1185 18.8811L21.3595 28.4234L29.171 6.10495L6.85249 13.9164Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};
