/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const LiChevronLeft = ({ color = "#3A3555", className }) => {
  return (
    <svg
      className={`li-chevron-left ${className}`}
      fill="none"
      height="10"
      viewBox="0 0 10 10"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M6.65035 2.25403C6.80354 2.40722 6.80354 2.65559 6.65035 2.80878L4.5741 4.88503L6.65035 6.96128C6.80354 7.11447 6.80354 7.36284 6.65035 7.51603C6.49715 7.66922 6.24878 7.66922 6.09559 7.51603L3.74197 5.16241C3.58878 5.00921 3.58878 4.76084 3.74197 4.60765L6.09559 2.25403C6.24878 2.10084 6.49715 2.10084 6.65035 2.25403Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

LiChevronLeft.propTypes = {
  color: PropTypes.string,
};
