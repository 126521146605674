import React, { FC } from "react";
import "./policy-style.css";
import { RoutePaths } from "../../../../App";
import { Link } from "react-router-dom";

interface IPolicyProps {
  title: string;
  children: React.ReactNode;
}
export const Policy: FC<IPolicyProps> = (props) => {
  const { children } = props;
  return (
    <div className="policy-frame">
      <div className="div">
        <p className="text-wrapper">{props.title}</p>
        <Link to={RoutePaths.home}>
          <img className="img" alt="Frame" src="/img/frame-39.svg" />
        </Link>

        <div className="text-wrapper-2">{children}</div>
      </div>
    </div>
  );
};
