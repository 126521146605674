/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconQrCode = ({ className }) => {
  return (
    <svg
      className={`icon-qr-code ${className}`}
      fill="none"
      height="61"
      viewBox="0 0 62 61"
      width="62"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" opacity="0.2">
        <path
          className="path"
          d="M17.9788 7.6759H10.3701C8.96937 7.6759 7.83386 8.81141 7.83386 10.2121V17.8208C7.83386 19.2216 8.96937 20.3571 10.3701 20.3571H17.9788C19.3795 20.3571 20.515 19.2216 20.515 17.8208V10.2121C20.515 8.81141 19.3795 7.6759 17.9788 7.6759Z"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.81028"
        />
        <path
          className="path"
          d="M50.9499 7.6759H43.3412C41.9404 7.6759 40.8049 8.81141 40.8049 10.2121V17.8208C40.8049 19.2216 41.9404 20.3571 43.3412 20.3571H50.9499C52.3506 20.3571 53.4861 19.2216 53.4861 17.8208V10.2121C53.4861 8.81141 52.3506 7.6759 50.9499 7.6759Z"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.81028"
        />
        <path
          className="path"
          d="M17.9788 40.647H10.3701C8.96937 40.647 7.83386 41.7825 7.83386 43.1832V50.7919C7.83386 52.1926 8.96937 53.3281 10.3701 53.3281H17.9788C19.3795 53.3281 20.515 52.1926 20.515 50.7919V43.1832C20.515 41.7825 19.3795 40.647 17.9788 40.647Z"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.81028"
        />
        <path
          className="path"
          d="M53.4861 40.647H45.8774C44.5321 40.647 43.2419 41.1814 42.2906 42.1327C41.3394 43.0839 40.8049 44.3741 40.8049 45.7194V53.3281"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.81028"
        />
        <path
          className="path"
          d="M53.4861 53.3281V53.3535"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.81028"
        />
        <path
          className="path"
          d="M30.66 17.8208V25.4295C30.66 26.7748 30.1256 28.065 29.1744 29.0163C28.2231 29.9675 26.9329 30.502 25.5876 30.502H17.9789"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.81028"
        />
        <path
          className="path"
          d="M7.83386 30.5021H7.85922"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.81028"
        />
        <path
          className="path"
          d="M30.66 7.6759H30.6854"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.81028"
        />
        <path
          className="path"
          d="M30.66 40.647V40.6723"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.81028"
        />
        <path
          className="path"
          d="M40.8049 30.5021H43.3412"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.81028"
        />
        <path
          className="path"
          d="M53.4861 30.5021V30.5274"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.81028"
        />
        <path
          className="path"
          d="M30.66 53.3282V50.792"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.81028"
        />
      </g>
    </svg>
  );
};
