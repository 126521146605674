/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiQrCodeReady = ({ className }) => {
  return (
    <svg
      className={`li-qr-code-ready ${className}`}
      fill="none"
      height="15"
      viewBox="0 0 15 15"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_429_428)">
        <path
          className="path"
          clipRule="evenodd"
          d="M3.21442 1.45304C3.26336 1.18819 3.51775 1.01316 3.7826 1.0621L7.61912 1.77109C7.88398 1.82003 8.05901 2.07442 8.01006 2.33928L7.30108 6.1758C7.25213 6.44065 6.99775 6.61568 6.73289 6.56674L2.89637 5.85775C2.63152 5.80881 2.45648 5.55442 2.50543 5.28956L3.21442 1.45304ZM4.08492 2.10986L3.55318 4.98724L6.43057 5.51898L6.96231 2.6416L4.08492 2.10986Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M4.7782 3.72581C4.82714 3.46095 5.08153 3.28592 5.34639 3.33487L5.35118 3.33576C5.61604 3.3847 5.79107 3.63909 5.74212 3.90394C5.69318 4.1688 5.43879 4.34383 5.17394 4.29489L5.16914 4.294C4.90428 4.24505 4.72925 3.99067 4.7782 3.72581Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M8.96924 2.51652C9.01818 2.25167 9.27257 2.07663 9.53743 2.12558L13.3739 2.83457C13.6388 2.88351 13.8138 3.1379 13.7649 3.40275L13.0559 7.23927C13.007 7.50413 12.7526 7.67916 12.4877 7.63021L8.65119 6.92123C8.38634 6.87228 8.21131 6.6179 8.26025 6.35304L8.96924 2.51652ZM9.83974 3.17333L9.308 6.05072L12.1854 6.58246L12.7171 3.70507L9.83974 3.17333Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M10.533 4.78929C10.5819 4.52443 10.8363 4.3494 11.1011 4.39835L11.1059 4.39923C11.3708 4.44818 11.5458 4.70256 11.4969 4.96742C11.4479 5.23228 11.1936 5.40731 10.9287 5.35836L10.9239 5.35748C10.659 5.30853 10.484 5.05414 10.533 4.78929Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M2.15094 7.20784C2.19989 6.94298 2.45427 6.76795 2.71913 6.81689L6.55565 7.52588C6.8205 7.57483 6.99553 7.82921 6.94659 8.09407L6.2376 11.9306C6.18866 12.1954 5.93427 12.3705 5.66941 12.3215L1.8329 11.6125C1.56804 11.5636 1.39301 11.3092 1.44195 11.0444L2.15094 7.20784ZM3.02145 7.86465L2.48971 10.742L5.36709 11.2738L5.89883 8.39639L3.02145 7.86465Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M3.71472 9.4806C3.76367 9.21575 4.01805 9.04072 4.28291 9.08966L4.28771 9.09055C4.55256 9.13949 4.72759 9.39388 4.67865 9.65874C4.6297 9.92359 4.37532 10.0986 4.11046 10.0497L4.10566 10.0488C3.84081 9.99985 3.66578 9.74546 3.71472 9.4806Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M7.90576 8.27131C7.95471 8.00646 8.20909 7.83143 8.47395 7.88037L8.47875 7.88126C8.7436 7.9302 8.91863 8.18459 8.86969 8.44945C8.82074 8.7143 8.56636 8.88933 8.3015 8.84039L8.2967 8.8395C8.03185 8.79056 7.85682 8.53617 7.90576 8.27131Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M12.3417 10.3735C12.4944 10.5954 12.4383 10.899 12.2164 11.0517L9.50147 12.9197C9.27958 13.0723 8.97594 13.0162 8.82327 12.7943L7.99394 11.589C7.84127 11.3671 7.89738 11.0635 8.11927 10.9108C8.34116 10.7582 8.64481 10.8143 8.79748 11.0362L9.35037 11.8397L11.6635 10.2481C11.8854 10.0955 12.189 10.1516 12.3417 10.3735Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M9.82397 8.6258C9.87292 8.36095 10.1273 8.18592 10.3922 8.23486L12.3104 8.58936C12.5753 8.6383 12.7503 8.89269 12.7014 9.15754C12.6524 9.4224 12.398 9.59743 12.1332 9.54849L10.2149 9.19399C9.95006 9.14505 9.77503 8.89066 9.82397 8.6258Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_429_428">
          <rect
            className="rect"
            fill="white"
            height="11.7044"
            transform="translate(2.91211 0.405304) rotate(10.4701)"
            width="11.7044"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
