import { Policy } from "../components/policy/screens/Frame/Policy";

export const RefundPolicy = () => {
  return (
    <Policy title={"Chính Sách Hoàn Trả"}>
      <p style={{ textAlign: "center" }}>
        <strong>
          CH&Iacute;NH S&Aacute;CH KIỂM H&Agrave;NG, ĐỔI TRẢ. HO&Agrave;N TIỀN
          V&Agrave; BẢO H&Agrave;NH
        </strong>
      </p>
      <p>
        Tại Vev&eacute;, ch&uacute;ng t&ocirc;i tr&acirc;n trọng sự tin tưởng
        của Kh&aacute;ch h&agrave;ng khi đặt mua để sử dụng dịch vụ tr&ecirc;n
        S&agrave;n thương mại điện tử Vev&eacute;. Ch&iacute;nh s&aacute;ch hậu
        m&atilde;i ở Vev&eacute; được x&acirc;y dựng dựa tr&ecirc;n cam kết bảo
        vệ quyền lợi người ti&ecirc;u d&ugrave;ng để qu&yacute; Kh&aacute;ch
        h&agrave;ng c&oacute; thể y&ecirc;n t&acirc;m mua sắm v&agrave; trải
        nghiệm dịch vụ.
      </p>
      <p>
        <strong>1. Ch&iacute;nh s&aacute;ch kiểm h&agrave;ng</strong>
      </p>
      <p>
        Vev&eacute; sẽ gửi v&eacute; đến Kh&aacute;ch h&agrave;ng sau khi đặt
        v&eacute; như sau:
      </p>
      <p>
        &bull; V&eacute; được mua tr&ecirc;n Vev&eacute; sẽ được thể hiện dưới
        h&igrave;nh thức V&eacute; điện tử v&agrave; sẽ được gửi đến
        Kh&aacute;ch h&agrave;ng (người mua) bằng m&atilde; x&aacute;c nhận
        tr&ecirc;n t&agrave;i khoản Vev&eacute; v&agrave; m&atilde; QR sẽ được
        mở trước giờ sự kiện 02 tiếng. Qu&yacute; kh&aacute;ch c&oacute; thể
        kiểm tra th&ocirc;ng tin về v&eacute; tại phần &ldquo;V&eacute;&rdquo;
        tr&ecirc;n ứng dụng.
      </p>
      <p>
        &bull; Đối với một số sự kiện lớn theo y&ecirc;u cầu của Nh&agrave; tổ
        chức sự kiện, th&igrave; v&eacute; c&oacute; thể được thể hiện dưới
        h&igrave;nh thức v&eacute; giấy, v&agrave; Kh&aacute;ch h&agrave;ng phải
        thanh to&aacute;n th&ecirc;m một khoản ph&iacute; cho b&ecirc;n vận
        chuyển thứ ba để vận chuyển v&eacute; giấy từ Vev&eacute; đến
        Kh&aacute;ch h&agrave;ng v&agrave; Kh&aacute;ch h&agrave;ng c&oacute;
        quyền kiểm v&eacute; khi được giao.
      </p>
      <p>
        <strong>2. Ch&iacute;nh s&aacute;ch đổi trả</strong>
      </p>
      <p>
        Ch&iacute;nh s&aacute;ch đối trả, ho&agrave;n tiền sẽ được quy định bởi
        Ban tổ chức sự kiện cho mỗi sự kiện cụ thể. Trường hợp sự kiện gặp sự cố
        dời ng&agrave;y tổ chức hoặc sự kiện bị hủy m&agrave; do l&yacute; do từ
        Nh&agrave; tổ chứ sự kiện, Kh&aacute;ch h&agrave;ng c&oacute; thể
        y&ecirc;u cầu ho&agrave;n trả v&eacute; v&agrave; ho&agrave;n tiền. Việc
        ho&agrave;n trả v&eacute; v&agrave; ho&agrave;n tiền sẽ do Nh&agrave; tổ
        chức sự kiện quyết định
      </p>
      <p>
        <strong>3. Ch&iacute;nh s&aacute;ch ho&agrave;n tiền</strong>
      </p>
      <p>
        S&agrave;n giao dịch TMĐT Vev&eacute; chỉ tiến h&agrave;nh bảo
        h&agrave;nh, hỗ trợ người d&ugrave;ng trong trường hợp Th&agrave;nh
        vi&ecirc;n l&agrave; Nh&agrave; tổ chức kh&ocirc;ng tiến h&agrave;nh tạo
        được gian h&agrave;ng, đăng b&aacute;n v&eacute; c&aacute;c sự kiện. Đối
        với v&eacute; chương tr&igrave;nh, sự kiện sẽ do Nh&agrave; tổ chức sự
        kiện quy định c&aacute;c điều khoản li&ecirc;n quan đến bảo h&agrave;nh,
        hướng dẫn sử dụng cho Người tham gia sự kiện
      </p>
    </Policy>
  );
};
