/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconChevronsUpDown = ({ className }) => {
  return (
    <svg
      className={`icon-chevrons-up-down ${className}`}
      fill="none"
      height="8"
      viewBox="0 0 7 8"
      width="7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_448_1541)">
        <path
          className="path"
          d="M2.7489 4.93091L4.13453 5.74622L4.94984 4.3606"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.511567"
        />
        <path
          className="path"
          d="M2.40674 3.61036L3.22205 2.22473L4.60768 3.04005"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.511567"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_448_1541">
          <rect
            className="rect"
            fill="white"
            height="5.45671"
            transform="translate(0.352783 2.02875) rotate(-14.527)"
            width="5.45671"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
