import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { TermOfService } from "./pages/TermOfService";
import { RefundPolicy } from "./pages/RefundPolicy";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { DisputeSettlementPolicy } from "./pages/DisputeSettlementPolicy";
export const RoutePaths = {
  home: "/",
  termsOfService: "terms-of-service",
  refundPolicy: "refund-policy",
  privacyPolicy: "privacy-policy",
  disputeSettlementPolicy: "dispute-settlement-policy",
};
function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path={RoutePaths.home} element={<HomePage />}></Route>
        <Route path={RoutePaths.termsOfService} element={<TermOfService />} />
        <Route path={RoutePaths.refundPolicy} element={<RefundPolicy />} />
        <Route path={RoutePaths.privacyPolicy} element={<PrivacyPolicy />} />
        <Route
          path={RoutePaths.disputeSettlementPolicy}
          element={<DisputeSettlementPolicy />}
        />
      </Routes>
    </HashRouter>
  );
}

export default App;
