/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiCompass1 = ({ className }) => {
  return (
    <svg
      className={`li-compass-1 ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 21 21"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_429_399)">
        <path
          className="path"
          clipRule="evenodd"
          d="M11.4986 3.89299C7.92307 3.23222 4.48886 5.59513 3.8281 9.17069C3.16733 12.7463 5.53024 16.1805 9.1058 16.8412C12.6814 17.502 16.1156 15.1391 16.7763 11.5635C17.4371 7.98796 15.0742 4.55375 11.4986 3.89299ZM2.3894 8.90482C3.197 4.5347 7.39437 1.64669 11.7645 2.45429C16.1346 3.26189 19.0226 7.45926 18.215 11.8294C17.4074 16.1995 13.2101 19.0875 8.83993 18.2799C4.46981 17.4723 1.5818 13.275 2.3894 8.90482Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M14.5185 7.46607C14.6756 7.69434 14.6902 7.99175 14.5563 8.23432L12.1858 12.5275C12.0745 12.7291 11.8748 12.8665 11.6468 12.8984L6.78991 13.578C6.5155 13.6164 6.24295 13.4964 6.08589 13.2682C5.92883 13.0399 5.91422 12.7425 6.04815 12.4999L8.41863 8.20669C8.52992 8.00512 8.72963 7.86771 8.95766 7.83581L13.8145 7.15626C14.0889 7.11786 14.3615 7.2378 14.5185 7.46607ZM9.52262 9.23406L8.03612 11.9263L11.0818 11.5002L12.5683 8.80793L9.52262 9.23406Z"
          fill="#636073"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_429_399">
          <rect
            className="rect"
            fill="white"
            height="17.5567"
            transform="translate(3.26526 0.139725) rotate(10.4701)"
            width="17.5567"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
