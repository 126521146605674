/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiUser = ({ className }) => {
  return (
    <svg
      className={`li-user ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_429_332)">
        <path
          className="path"
          clipRule="evenodd"
          d="M6.23791 14.4337C6.71993 13.6145 7.50762 13.0203 8.4277 12.7819L13.9784 11.3436C14.8985 11.1052 15.8756 11.2421 16.6947 11.7241C17.5139 12.2061 18.1081 12.9938 18.3465 13.9139L18.706 15.3015C18.8053 15.6847 18.5752 16.0759 18.192 16.1752C17.8088 16.2745 17.4177 16.0443 17.3184 15.6611L16.9588 14.2734C16.8158 13.7214 16.4593 13.2488 15.9678 12.9596C15.4763 12.6704 14.89 12.5883 14.3379 12.7313L8.78727 14.1696C8.23522 14.3126 7.76261 14.6691 7.4734 15.1606C7.18419 15.6521 7.10208 16.2384 7.24513 16.7905L7.6047 18.1781C7.70399 18.5613 7.47385 18.9525 7.09065 19.0517C6.70746 19.151 6.31633 18.9209 6.21703 18.5377L5.85746 17.15C5.61905 16.23 5.7559 15.2528 6.23791 14.4337Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M9.40516 5.12445C8.25558 5.42233 7.56514 6.59573 7.86302 7.74531C8.1609 8.89489 9.3343 9.58533 10.4839 9.28745C11.6335 8.98957 12.3239 7.81617 12.026 6.66659C11.7281 5.51701 10.5547 4.82657 9.40516 5.12445ZM6.47535 8.10488C5.97888 6.18891 7.12962 4.23325 9.04559 3.73678C10.9616 3.24031 12.9172 4.39105 13.4137 6.30702C13.9102 8.22299 12.7594 10.1787 10.8435 10.6751C8.92748 11.1716 6.97181 10.0209 6.47535 8.10488Z"
          fill="#636073"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_429_332">
          <rect
            className="rect"
            fill="white"
            height="17.202"
            transform="translate(0.360016 4.50655) rotate(-14.527)"
            width="17.202"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
