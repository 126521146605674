/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiMapPin3 = ({ className }) => {
  return (
    <svg
      className={`li-map-pin-3 ${className}`}
      fill="none"
      height="5"
      viewBox="0 0 6 5"
      width="6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_448_1504)">
        <path
          className="path"
          clipRule="evenodd"
          d="M3.24883 1.10293C2.94623 1.04701 2.63381 1.11358 2.38031 1.28801C2.1268 1.46244 1.95296 1.73042 1.89704 2.03302C1.81539 2.47485 2.02364 2.95671 2.27737 3.34769C2.40189 3.53956 2.53236 3.70191 2.63185 3.81637C2.66888 3.85897 2.70148 3.89477 2.72771 3.92283C2.76223 3.906 2.80548 3.88421 2.85528 3.85766C2.98911 3.78632 3.16899 3.68132 3.35386 3.54663C3.73056 3.27215 4.09727 2.89654 4.17892 2.45471C4.23484 2.15211 4.16826 1.8397 3.99383 1.58619C3.81941 1.33268 3.55142 1.15885 3.24883 1.10293ZM2.69536 4.09788C2.59432 4.20193 2.59429 4.2019 2.59425 4.20186L2.5939 4.20152L2.59306 4.2007L2.5902 4.19789C2.58777 4.19549 2.58431 4.19205 2.5799 4.1876C2.57106 4.17872 2.5584 4.1658 2.54249 4.14916C2.5107 4.11588 2.46591 4.06761 2.41294 4.00667C2.30718 3.88501 2.16783 3.71174 2.03405 3.50559C1.77119 3.10054 1.50897 2.53679 1.61181 1.98031C1.68171 1.60206 1.899 1.26708 2.21589 1.04905C2.53277 0.831015 2.92329 0.747794 3.30154 0.817693C3.67978 0.887593 4.01476 1.10489 4.2328 1.42177C4.45083 1.73866 4.53405 2.12917 4.46415 2.50742C4.36131 3.0639 3.91493 3.4967 3.52467 3.78106C3.32605 3.92578 3.13398 4.0378 2.99173 4.11363C2.92047 4.15161 2.86139 4.18069 2.8198 4.20041C2.799 4.21027 2.78256 4.2178 2.77113 4.22294C2.76542 4.22551 2.76096 4.22749 2.75783 4.22886L2.75415 4.23047L2.75308 4.23093L2.75275 4.23108C2.7527 4.2311 2.75254 4.23117 2.69536 4.09788ZM2.69536 4.09788L2.75254 4.23117C2.69873 4.25425 2.63626 4.24265 2.59425 4.20186L2.69536 4.09788Z"
          fill="#3A3555"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M3.09063 1.95865C2.9331 1.92954 2.7818 2.03364 2.75269 2.19117C2.72358 2.3487 2.82768 2.50001 2.98521 2.52912C3.14274 2.55823 3.29404 2.45412 3.32316 2.29659C3.35227 2.13906 3.24816 1.98776 3.09063 1.95865ZM2.46745 2.13846C2.52568 1.8234 2.82828 1.61519 3.14334 1.67342C3.4584 1.73164 3.66661 2.03424 3.60839 2.34931C3.55017 2.66437 3.24756 2.87257 2.9325 2.81435C2.61744 2.75613 2.40923 2.45352 2.46745 2.13846Z"
          fill="#3A3555"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_448_1504">
          <rect
            className="rect"
            fill="white"
            height="3.48076"
            transform="translate(1.59009 0.501434) rotate(10.4701)"
            width="3.48076"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
