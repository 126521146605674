/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiTicket1 = ({ className }) => {
  return (
    <svg
      className={`li-ticket-1 ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 21 21"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_429_402)">
        <path
          className="path"
          clipRule="evenodd"
          d="M6.34511 5.18432C6.15433 5.14906 5.95736 5.19104 5.79752 5.30101C5.63769 5.41099 5.52809 5.57995 5.49283 5.77073L5.22696 7.20943C5.15354 7.60671 4.77196 7.86926 4.37468 7.79584C3.9774 7.72242 3.71485 7.34084 3.78827 6.94356L4.05414 5.50486C4.15991 4.93252 4.48871 4.42563 4.96821 4.09571C5.44771 3.76578 6.03864 3.63986 6.61098 3.74563L16.6818 5.60672C17.2542 5.71249 17.7611 6.04129 18.091 6.52079C18.4209 7.00029 18.5468 7.59121 18.4411 8.16356L18.1752 9.60226C18.1018 9.99954 17.7202 10.2621 17.3229 10.1887C16.9256 10.1153 16.6631 9.73367 16.7365 9.33639L17.0024 7.89769C17.0376 7.70691 16.9957 7.50993 16.8857 7.3501C16.7757 7.19027 16.6068 7.08067 16.416 7.04541L6.34511 5.18432ZM3.84294 10.6732C4.24022 10.7466 4.50277 11.1282 4.42935 11.5255L4.16348 12.9642C4.12823 13.155 4.1702 13.352 4.28018 13.5118C4.39015 13.6716 4.55911 13.7812 4.74989 13.8165L14.8208 15.6776C15.0115 15.7128 15.2085 15.6709 15.3683 15.5609C15.5282 15.4509 15.6378 15.2819 15.673 15.0912L15.9389 13.6525C16.0123 13.2552 16.3939 12.9926 16.7912 13.0661C17.1885 13.1395 17.451 13.5211 17.3776 13.9183L17.1117 15.357C17.006 15.9294 16.6772 16.4363 16.1977 16.7662C15.7182 17.0961 15.1272 17.222 14.5549 17.1163L4.48402 15.2552C3.91168 15.1494 3.40479 14.8206 3.07487 14.3411C2.74495 13.8616 2.61902 13.2707 2.72479 12.6983L2.99066 11.2596C3.06408 10.8624 3.44566 10.5998 3.84294 10.6732Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M17.3229 10.1887C16.9414 10.1182 16.5474 10.2021 16.2278 10.4221C15.9081 10.642 15.6889 10.9799 15.6184 11.3615C15.5479 11.7431 15.6318 12.137 15.8518 12.4567C16.0717 12.7763 16.4096 12.9955 16.7912 13.0661C17.1885 13.1395 17.451 13.5211 17.3776 13.9183C17.3042 14.3156 16.9226 14.5782 16.5253 14.5048C15.7622 14.3637 15.0864 13.9253 14.6465 13.286C14.2066 12.6467 14.0387 11.8588 14.1797 11.0956C14.3207 10.3325 14.7591 9.65664 15.3985 9.21675C16.0378 8.77685 16.8257 8.60895 17.5888 8.74997C17.9861 8.82339 18.2486 9.20497 18.1752 9.60226C18.1018 9.99954 17.7202 10.2621 17.3229 10.1887Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M3.78827 6.94356C3.86169 6.54628 4.24327 6.28373 4.64055 6.35715C5.40368 6.49817 6.07953 6.93658 6.51943 7.57591C6.95932 8.21525 7.12723 9.00314 6.9862 9.76628C6.84517 10.5294 6.40677 11.2053 5.76744 11.6452C5.1281 12.085 4.3402 12.253 3.57707 12.1119C3.17979 12.0385 2.91724 11.6569 2.99066 11.2596C3.06408 10.8624 3.44566 10.5998 3.84294 10.6732C4.22451 10.7437 4.61846 10.6598 4.93812 10.4398C5.25779 10.2199 5.47699 9.88197 5.54751 9.50041C5.61802 9.11884 5.53407 8.72489 5.31412 8.40522C5.09417 8.08556 4.75625 7.86635 4.37468 7.79584C3.9774 7.72242 3.71485 7.34084 3.78827 6.94356Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M12.3658 4.80911C12.7631 4.88253 13.0256 5.2641 12.9522 5.66139L12.6863 7.10008C12.6129 7.49737 12.2313 7.75991 11.834 7.6865C11.4367 7.61308 11.1742 7.2315 11.2476 6.83421L11.5135 5.39552C11.5869 4.99823 11.9685 4.73569 12.3658 4.80911Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M10.7706 13.4413C11.1678 13.5147 11.4304 13.8963 11.357 14.2936L11.0911 15.7322C11.0177 16.1295 10.6361 16.3921 10.2388 16.3187C9.84153 16.2452 9.57899 15.8637 9.6524 15.4664L9.91827 14.0277C9.99169 13.6304 10.3733 13.3679 10.7706 13.4413Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M11.5682 9.12519C11.9654 9.19861 12.228 9.58019 12.1546 9.97747L11.8887 11.4162C11.8153 11.8134 11.4337 12.076 11.0364 12.0026C10.6391 11.9292 10.3766 11.5476 10.45 11.1503L10.7159 9.7116C10.7893 9.31432 11.1709 9.05177 11.5682 9.12519Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_429_402">
          <rect
            className="rect"
            fill="white"
            height="17.5567"
            transform="translate(3.54599 0.203566) rotate(10.4701)"
            width="17.5567"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
