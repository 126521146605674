/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconQrCode = ({ className }) => {
  return (
    <svg
      className={`icon-qr-code ${className}`}
      fill="none"
      height="110"
      viewBox="0 0 110 110"
      width="110"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" opacity="0.2">
        <path
          className="path"
          d="M32.0833 13.75H18.3333C15.802 13.75 13.75 15.802 13.75 18.3333V32.0833C13.75 34.6146 15.802 36.6667 18.3333 36.6667H32.0833C34.6146 36.6667 36.6667 34.6146 36.6667 32.0833V18.3333C36.6667 15.802 34.6146 13.75 32.0833 13.75Z"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="10.5"
        />
        <path
          className="path"
          d="M91.6667 13.75H77.9167C75.3854 13.75 73.3333 15.802 73.3333 18.3333V32.0833C73.3333 34.6146 75.3854 36.6667 77.9167 36.6667H91.6667C94.198 36.6667 96.25 34.6146 96.25 32.0833V18.3333C96.25 15.802 94.198 13.75 91.6667 13.75Z"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="10.5"
        />
        <path
          className="path"
          d="M32.0833 73.3333H18.3333C15.802 73.3333 13.75 75.3853 13.75 77.9166V91.6666C13.75 94.1979 15.802 96.2499 18.3333 96.2499H32.0833C34.6146 96.2499 36.6667 94.1979 36.6667 91.6666V77.9166C36.6667 75.3853 34.6146 73.3333 32.0833 73.3333Z"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="10.5"
        />
        <path
          className="path"
          d="M96.25 73.3333H82.5C80.0689 73.3333 77.7373 74.299 76.0182 76.0181C74.2991 77.7372 73.3333 80.0688 73.3333 82.4999V96.2499"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="10.5"
        />
        <path
          className="path"
          d="M96.25 96.25V96.2958"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="10.5"
        />
        <path
          className="path"
          d="M55 32.0833V45.8333C55 48.2644 54.0342 50.596 52.3152 52.3151C50.5961 54.0341 48.2645 54.9999 45.8333 54.9999H32.0833"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="10.5"
        />
        <path
          className="path"
          d="M13.75 55H13.7958"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="10.5"
        />
        <path
          className="path"
          d="M55 13.75H55.0458"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="10.5"
        />
        <path
          className="path"
          d="M55 73.3333V73.3791"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="10.5"
        />
        <path
          className="path"
          d="M73.3333 55H77.9167"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="10.5"
        />
        <path
          className="path"
          d="M96.25 55V55.0458"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="10.5"
        />
        <path
          className="path"
          d="M55 96.2501V91.6667"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="10.5"
        />
      </g>
    </svg>
  );
};
