/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiSend1 = ({ className }) => {
  return (
    <svg
      className={`li-send-1 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 19 20"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_448_2494)">
        <path
          className="path"
          clipRule="evenodd"
          d="M17.9715 1.17534C18.2795 1.48334 18.2795 1.98272 17.9715 2.29072L9.29585 10.9664C8.98785 11.2744 8.48847 11.2744 8.18047 10.9664C7.87246 10.6584 7.87246 10.159 8.18047 9.85099L16.8561 1.17534C17.1641 0.867335 17.6635 0.867335 17.9715 1.17534Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M17.9715 1.17536C18.1857 1.3896 18.2583 1.70763 18.1582 1.9936L12.6374 17.7675C12.5305 18.0727 12.2482 18.2818 11.9251 18.295C11.602 18.3082 11.3036 18.1228 11.1722 17.8273L8.14064 11.0062L1.31958 7.97464C1.02407 7.84331 0.838674 7.54489 0.851856 7.22177C0.865038 6.89866 1.07412 6.61634 1.37935 6.50951L17.1533 0.988637C17.4392 0.888547 17.7573 0.961122 17.9715 1.17536ZM3.77811 7.34116L9.05848 9.68799C9.23691 9.76729 9.37958 9.90996 9.45888 10.0884L11.8057 15.3688L16.1283 3.01861L3.77811 7.34116Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_448_2494">
          <rect className="rect" fill="white" height="18.9287" transform="translate(0.0625 0.155518)" width="18.9287" />
        </clipPath>
      </defs>
    </svg>
  );
};
