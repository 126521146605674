/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconChevronsUpDown = ({ className }) => {
  return (
    <svg
      className={`icon-chevrons-up-down ${className}`}
      fill="none"
      height="15"
      viewBox="0 0 15 15"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M5.92865 9.58676L8.84072 11.3003L10.5542 8.38819"
        stroke="#334155"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.07512"
      />
      <path
        className="path"
        d="M5.2095 6.81141L6.92299 3.89934L9.83506 5.61283"
        stroke="#334155"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.07512"
      />
    </svg>
  );
};
