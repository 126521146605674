/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiQrCodeReady = ({ className }) => {
  return (
    <svg
      className={`li-qr-code-ready ${className}`}
      fill="none"
      height="7"
      viewBox="0 0 8 7"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_448_1492)">
        <path
          className="path"
          clipRule="evenodd"
          d="M1.68806 0.607734C1.71134 0.48171 1.83239 0.398427 1.95841 0.421716L3.78391 0.759067C3.90993 0.782356 3.99322 0.903398 3.96993 1.02942L3.63258 2.85492C3.60929 2.98094 3.48824 3.06423 3.36222 3.04094L1.53672 2.70359C1.4107 2.6803 1.32742 2.55926 1.35071 2.43323L1.68806 0.607734ZM2.10226 0.920259L1.84925 2.28938L3.21837 2.54239L3.47138 1.17327L2.10226 0.920259Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M2.43207 1.68916C2.45536 1.56313 2.57641 1.47985 2.70243 1.50314L2.70471 1.50356C2.83074 1.52685 2.91402 1.64789 2.89073 1.77391C2.86744 1.89994 2.7464 1.98322 2.62037 1.95993L2.61809 1.95951C2.49207 1.93622 2.40879 1.81518 2.43207 1.68916Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M4.42634 1.11375C4.44963 0.987722 4.57067 0.904439 4.69669 0.927728L6.52219 1.26508C6.64821 1.28837 6.7315 1.40941 6.70821 1.53543L6.37086 3.36093C6.34757 3.48696 6.22653 3.57024 6.1005 3.54695L4.275 3.2096C4.14898 3.18631 4.0657 3.06527 4.08899 2.93924L4.42634 1.11375ZM4.84054 1.42627L4.58753 2.79539L5.95665 3.04841L6.20967 1.67928L4.84054 1.42627Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M5.17036 2.19517C5.19364 2.06914 5.31469 1.98586 5.44071 2.00915L5.44299 2.00957C5.56902 2.03286 5.6523 2.1539 5.62901 2.27993C5.60572 2.40595 5.48468 2.48923 5.35866 2.46594L5.35637 2.46552C5.23035 2.44223 5.14707 2.32119 5.17036 2.19517Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M1.18207 3.34598C1.20536 3.21996 1.32641 3.13668 1.45243 3.15997L3.27793 3.49732C3.40395 3.52061 3.48723 3.64165 3.46395 3.76767L3.1266 5.59317C3.10331 5.71919 2.98226 5.80248 2.85624 5.77919L1.03074 5.44184C0.904718 5.41855 0.821434 5.29751 0.844724 5.17148L1.18207 3.34598ZM1.59628 3.65851L1.34327 5.02763L2.71239 5.28065L2.9654 3.91152L1.59628 3.65851Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M1.92609 4.42741C1.94938 4.30138 2.07042 4.2181 2.19645 4.24139L2.19873 4.24181C2.32476 4.2651 2.40804 4.38614 2.38475 4.51217C2.36146 4.63819 2.24042 4.72147 2.11439 4.69818L2.11211 4.69776C1.98609 4.67447 1.9028 4.55343 1.92609 4.42741Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M3.92023 3.852C3.94352 3.72597 4.06457 3.64269 4.19059 3.66598L4.19287 3.6664C4.3189 3.68969 4.40218 3.81073 4.37889 3.93676C4.3556 4.06278 4.23456 4.14606 4.10853 4.12277L4.10625 4.12235C3.98023 4.09906 3.89694 3.97802 3.92023 3.852Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M6.03103 4.85227C6.10367 4.95786 6.07697 5.10234 5.97139 5.17498L4.67958 6.06381C4.574 6.13645 4.42952 6.10975 4.35687 6.00417L3.96226 5.43066C3.88961 5.32508 3.91631 5.1806 4.02189 5.10795C4.12748 5.03531 4.27196 5.06201 4.3446 5.16759L4.60768 5.54993L5.70832 4.79264C5.8139 4.71999 5.95838 4.74669 6.03103 4.85227Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M4.83295 4.0207C4.85624 3.89467 4.97728 3.81139 5.10331 3.83468L6.01606 4.00336C6.14208 4.02664 6.22537 4.14769 6.20208 4.27371C6.17879 4.39974 6.05774 4.48302 5.93172 4.45973L5.01897 4.29105C4.89295 4.26776 4.80966 4.14672 4.83295 4.0207Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_448_1492">
          <rect
            className="rect"
            fill="white"
            height="5.56922"
            transform="translate(1.54419 0.109192) rotate(10.4701)"
            width="5.56922"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
