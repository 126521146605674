/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconMusic = ({ className }) => {
  return (
    <svg
      className={`icon-music ${className}`}
      fill="none"
      height="7"
      viewBox="0 0 8 7"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_448_1539)">
        <path
          className="path"
          d="M3.42553 4.97463L2.68413 2.11341L5.2112 0.988848L5.9526 3.85007"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.511567"
        />
        <path
          className="path"
          d="M2.9363 5.80603C3.30097 5.71154 3.51999 5.33932 3.42549 4.97465C3.331 4.60999 2.95878 4.39097 2.59412 4.48546C2.22946 4.57995 2.01044 4.95217 2.10493 5.31684C2.19942 5.6815 2.57164 5.90052 2.9363 5.80603Z"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.511567"
        />
        <path
          className="path"
          d="M5.4634 4.68145C5.82807 4.58696 6.04709 4.21474 5.95259 3.85008C5.8581 3.48542 5.48588 3.2664 5.12122 3.36089C4.75656 3.45538 4.53754 3.8276 4.63203 4.19226C4.72652 4.55693 5.09874 4.77595 5.4634 4.68145Z"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.511567"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_448_1539">
          <rect
            className="rect"
            fill="white"
            height="5.45671"
            transform="translate(0.418091 1.52625) rotate(-14.527)"
            width="5.45671"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
