/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiBell1 = ({ className }) => {
  return (
    <svg
      className={`li-bell-1 ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 22 21"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_429_405)">
        <path
          className="path"
          clipRule="evenodd"
          d="M8.4929 3.39883C9.61173 2.62901 10.9906 2.33518 12.326 2.58198C13.6615 2.82877 14.8443 3.59598 15.6141 4.71481C16.3839 5.83365 16.6777 7.21247 16.4309 8.54795C15.9852 10.9597 16.2243 12.5527 16.5386 13.512C16.6964 13.9937 16.8758 14.3237 17.0057 14.5239C17.0709 14.6243 17.124 14.6927 17.1564 14.7316C17.1726 14.7511 17.1836 14.7633 17.1884 14.7684C17.1891 14.7692 17.1897 14.7699 17.1901 14.7703C17.4153 14.9951 17.4695 15.3403 17.3232 15.6236C17.1752 15.91 16.8573 16.0659 16.5403 16.0073L3.59202 13.6145C3.27502 13.5559 3.03374 13.2967 2.99795 12.9763C2.96254 12.6594 3.13653 12.3564 3.42714 12.227C3.42772 12.2267 3.4285 12.2263 3.42948 12.2258C3.43578 12.2227 3.45043 12.2153 3.47253 12.2029C3.51671 12.1781 3.59075 12.1332 3.68744 12.0627C3.88038 11.9221 4.16583 11.678 4.48537 11.2846C5.12179 10.5009 5.91437 9.09859 6.36006 6.68686C6.60686 5.35138 7.37406 4.16864 8.4929 3.39883ZM3.43532 12.2234C3.43539 12.2233 3.43545 12.2233 3.43552 12.2233C3.43551 12.2233 3.43551 12.2233 3.43551 12.2233L3.43532 12.2234ZM5.404 12.4615L15.26 14.2829C15.2219 14.1831 15.1845 14.078 15.1483 13.9675C14.7491 12.7493 14.5074 10.9058 14.9922 8.28208C15.1685 7.32817 14.9586 6.34329 14.4088 5.54413C13.8589 4.74496 13.0141 4.19695 12.0602 4.02067C11.1063 3.84439 10.1214 4.05427 9.32221 4.60413C8.52304 5.154 7.97504 5.99882 7.79875 6.95273C7.3139 9.57644 6.42926 11.2118 5.62108 12.2069C5.54779 12.2971 5.4753 12.3819 5.404 12.4615Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M8.17691 16.05C8.5574 15.9142 8.97597 16.1125 9.11181 16.493C9.1549 16.6137 9.22894 16.7209 9.32652 16.804C9.4241 16.8871 9.54179 16.9431 9.66781 16.9664C9.79384 16.9897 9.92377 16.9794 10.0446 16.9367C10.1654 16.894 10.2729 16.8203 10.3563 16.723C10.6191 16.4161 11.0809 16.3805 11.3878 16.6434C11.6946 16.9062 11.7302 17.368 11.4673 17.6748C11.2172 17.9668 10.8948 18.1879 10.5323 18.3161C10.1698 18.4442 9.78001 18.4749 9.40195 18.4051C9.02388 18.3352 8.6708 18.1672 8.37806 17.918C8.08533 17.6688 7.8632 17.347 7.73394 16.9849C7.5981 16.6044 7.79642 16.1858 8.17691 16.05Z"
          fill="#636073"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_429_405">
          <rect
            className="rect"
            fill="white"
            height="17.5567"
            transform="translate(3.82678 0.267408) rotate(10.4701)"
            width="17.5567"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
