/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiSend2 = ({ className }) => {
  return (
    <svg
      className={`li-send-2 ${className}`}
      fill="none"
      height="15"
      viewBox="0 0 15 15"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_429_445)">
        <path
          className="path"
          clipRule="evenodd"
          d="M13.7031 3.14859C13.8558 3.37049 13.7997 3.67413 13.5778 3.8268L7.32772 8.12716C7.10582 8.27983 6.80218 8.22372 6.64951 8.00183C6.49684 7.77993 6.55295 7.47629 6.77484 7.32362L13.0249 3.02326C13.2468 2.87059 13.5504 2.9267 13.7031 3.14859Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M13.7031 3.1486C13.8093 3.30294 13.8177 3.50447 13.7247 3.66711L8.59527 12.638C8.49602 12.8116 8.30086 12.907 8.10291 12.8787C7.90496 12.8505 7.74434 12.7042 7.69768 12.5097L6.6208 8.02157L2.81392 5.41176C2.64899 5.29869 2.56979 5.09641 2.61412 4.90142C2.65844 4.70644 2.8173 4.55826 3.01489 4.5276L13.2266 2.94311C13.4117 2.91439 13.5969 2.99425 13.7031 3.1486ZM4.38 5.30283L7.32701 7.32315C7.4266 7.39142 7.49732 7.4942 7.52549 7.6116L8.35913 11.086L12.3752 4.06226L4.38 5.30283Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_429_445">
          <rect
            className="rect"
            fill="white"
            height="11.7044"
            transform="translate(2.92816 0.51619) rotate(10.4701)"
            width="11.7044"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
