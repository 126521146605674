/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiClock = ({ className }) => {
  return (
    <svg
      className={`li-clock ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 21 21"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_429_467)">
        <path
          className="path"
          clipRule="evenodd"
          d="M11.4476 4.00858C7.87205 3.34782 4.43783 5.71073 3.77707 9.28629C3.11631 12.8619 5.47922 16.2961 9.05478 16.9568C12.6303 17.6176 16.0646 15.2547 16.7253 11.6791C17.3861 8.10356 15.0232 4.66934 11.4476 4.00858ZM2.33838 9.02042C3.14597 4.65029 7.34335 1.76229 11.7135 2.56989C16.0836 3.37749 18.9716 7.57486 18.164 11.945C17.3564 16.3151 13.159 19.2031 8.78891 18.3955C4.41878 17.5879 1.53078 13.3906 2.33838 9.02042Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M11.1817 5.44727C11.579 5.52068 11.8416 5.90226 11.7682 6.29955L11.0527 10.171L13.3033 11.8692C13.6258 12.1125 13.69 12.5712 13.4467 12.8937C13.2033 13.2162 12.7446 13.2804 12.4221 13.0371L9.8106 11.0666C9.58942 10.8998 9.48151 10.6222 9.53186 10.3498L10.3295 6.03368C10.4029 5.63639 10.7845 5.37385 11.1817 5.44727Z"
          fill="#636073"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_429_467">
          <rect
            className="rect"
            fill="white"
            height="17.5567"
            transform="translate(3.21423 0.25531) rotate(10.4701)"
            width="17.5567"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
