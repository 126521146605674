/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiBell = ({ className }) => {
  return (
    <svg
      className={`li-bell ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_429_329)">
        <path
          className="path"
          clipRule="evenodd"
          d="M5.8347 5.67099C6.50952 4.52415 7.61229 3.69234 8.9004 3.35856C10.1885 3.02479 11.5565 3.21638 12.7033 3.8912C13.8502 4.56602 14.682 5.66878 15.0157 6.9569C15.6185 9.28308 16.4903 10.5987 17.1667 11.3205C17.5062 11.6829 17.8021 11.9017 18.0005 12.0257C18.0998 12.0878 18.1753 12.1266 18.2202 12.1478C18.2427 12.1584 18.2575 12.1646 18.2639 12.1672C18.2649 12.1676 18.2657 12.1679 18.2662 12.1682C18.5592 12.2746 18.7503 12.5586 18.7377 12.8708C18.7249 13.1864 18.507 13.4564 18.2013 13.5357L5.71226 16.7718C5.40651 16.851 5.08494 16.7208 4.92051 16.4511C4.75783 16.1843 4.78692 15.8433 4.99137 15.6079C4.99177 15.6075 4.9923 15.6068 4.99298 15.606C4.99729 15.6006 5.00723 15.588 5.02171 15.5678C5.05066 15.5274 5.09783 15.4569 5.15453 15.3543C5.26765 15.1496 5.42008 14.8147 5.54092 14.333C5.78161 13.3736 5.90483 11.8001 5.30207 9.47391C4.96829 8.18579 5.15989 6.81784 5.8347 5.67099ZM4.99715 15.6014C4.9972 15.6013 4.99724 15.6013 4.99729 15.6012C4.99729 15.6012 4.99728 15.6012 4.99728 15.6012L4.99715 15.6014ZM6.84396 14.9977L16.3504 12.5344C16.2752 12.4616 16.1985 12.3838 16.1206 12.3006C15.2618 11.3841 14.2838 9.84712 13.6281 7.31647C13.3897 6.39639 12.7955 5.6087 11.9763 5.12669C11.1572 4.64467 10.1801 4.50782 9.25997 4.74623C8.33989 4.98464 7.5522 5.57879 7.07019 6.39797C6.58818 7.21715 6.45132 8.19425 6.68973 9.11433C7.34548 11.645 7.23698 13.4635 6.93133 14.6818C6.90362 14.7923 6.87434 14.8975 6.84396 14.9977Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M10.7921 17.0363C11.0738 16.7582 11.5276 16.761 11.8057 17.0426C11.894 17.132 12.0041 17.1965 12.1252 17.2299C12.2462 17.2633 12.3739 17.2643 12.4955 17.2328C12.617 17.2013 12.7282 17.1384 12.8178 17.0505C12.9074 16.9625 12.9723 16.8525 13.0061 16.7316C13.1125 16.3503 13.5078 16.1274 13.8891 16.2338C14.2704 16.3402 14.4932 16.7356 14.3868 17.1168C14.2856 17.4797 14.0908 17.8096 13.822 18.0734C13.5532 18.3373 13.2197 18.526 12.8551 18.6205C12.4904 18.7149 12.1073 18.712 11.7442 18.6119C11.381 18.5117 11.0505 18.318 10.7858 18.0499C10.5077 17.7683 10.5105 17.3145 10.7921 17.0363Z"
          fill="#636073"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_429_329">
          <rect
            className="rect"
            fill="white"
            height="17.202"
            transform="translate(0.394592 4.82217) rotate(-14.527)"
            width="17.202"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
