/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiUser1 = ({ className }) => {
  return (
    <svg
      className={`li-user-1 ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 22 21"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_429_408)">
        <path
          className="path"
          clipRule="evenodd"
          d="M5.26326 12.049C6.06243 11.4992 7.0473 11.2893 8.00122 11.4656L13.756 12.5291C14.7099 12.7053 15.5547 13.2534 16.1046 14.0525C16.6545 14.8517 16.8643 15.8366 16.6881 16.7905L16.4222 18.2292C16.3488 18.6265 15.9672 18.889 15.5699 18.8156C15.1726 18.7422 14.9101 18.3606 14.9835 17.9633L15.2494 16.5246C15.3551 15.9523 15.2292 15.3613 14.8993 14.8818C14.5694 14.4023 14.0625 14.0735 13.4901 13.9678L7.73535 12.9043C7.163 12.7985 6.57207 12.9244 6.09257 13.2544C5.61307 13.5843 5.28427 14.0912 5.1785 14.6635L4.91263 16.1022C4.83921 16.4995 4.45763 16.762 4.06035 16.6886C3.66306 16.6152 3.40052 16.2336 3.47394 15.8363L3.73981 14.3976C3.91609 13.4437 4.46409 12.5989 5.26326 12.049Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M12.2079 4.80386C11.0161 4.5836 9.87135 5.37124 9.65109 6.56309C9.43084 7.75495 10.2185 8.89969 11.4103 9.11994C12.6022 9.34019 13.7469 8.55256 13.9672 7.3607C14.1874 6.16885 13.3998 5.02411 12.2079 4.80386ZM8.2124 6.29722C8.57949 4.3108 10.4874 2.99807 12.4738 3.36516C14.4602 3.73225 15.773 5.64015 15.4059 7.62657C15.0388 9.613 13.1309 10.9257 11.1445 10.5586C9.15804 10.1915 7.84531 8.28365 8.2124 6.29722Z"
          fill="#636073"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_429_408">
          <rect
            className="rect"
            fill="white"
            height="17.5567"
            transform="translate(4.10754 0.331249) rotate(10.4701)"
            width="17.5567"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
