import { Policy } from "../components/policy/screens/Frame/Policy";

export const TermOfService = () => {
  return (
    <Policy title={"Điều Khoản Sử Dụng"}>
      <p style={{ textAlign: "center" }}>
        <strong>
          ĐIỀU KHOẢN SỬ DỤNG &Aacute;P DỤNG CHO KH&Aacute;CH H&Agrave;NG
        </strong>
      </p>
      <p>
        <strong>1. Quy Định Chung</strong>
      </p>
      <p>
        Ch&agrave;o mừng Qu&yacute; Kh&aacute;ch h&agrave;ng đến với S&agrave;n
        giao dịch thương mại điện tử Vev&eacute; (bao gồm website v&agrave; ứng
        dụng di động) bao gồm: (a) website thương mại điện tử Veve.com.vn được
        đăng k&yacute; v&agrave; hoạt động như một ứng dụng di động tr&ecirc;n
        hệ điều h&agrave;nh Android v&agrave; iOS c&oacute; t&ecirc;n l&agrave;
        Vev&eacute; được đăng k&yacute; l&agrave; một ứng dụng thương mại điện
        tử để c&aacute;c thương nh&acirc;n, tổ chức, c&aacute; nh&acirc;n
        kh&aacute;c c&oacute; thể tiến h&agrave;nh to&agrave;n bộ quy
        tr&igrave;nh mua b&aacute;n h&agrave;ng h&oacute;a, dịch vụ tr&ecirc;n
        đ&oacute; theo h&igrave;nh thức hoạt động l&agrave;: ứng dụng cho
        ph&eacute;p người tham gia được mở c&aacute;c gian h&agrave;ng để trưng
        b&agrave;y, giới thiệu h&agrave;ng h&oacute;a hoặc dịch vụ v&agrave;
        Kh&aacute;ch h&agrave;ng thực hiện mua sắm tr&ecirc;n đ&oacute;. Điều
        khoản sử dụng n&agrave;y bao gồm điều khoản (&ldquo;Điều khoản sử
        dụng&rdquo;) mang t&iacute;nh r&agrave;ng buộc ph&aacute;p l&yacute;
        giữa Kh&aacute;ch h&agrave;ng (&ldquo;Kh&aacute;ch h&agrave;ng&rdquo;
        hoặc &ldquo;bạn&rdquo;) v&agrave; C&ocirc;ng ty TNHH Ubiz Solutions, một
        c&ocirc;ng ty sở hữu, điều h&agrave;nh v&agrave; cung cấp đa dạng
        c&aacute;c loại h&igrave;nh dịch vụ tuỳ thuộc v&agrave;o mức độ
        y&ecirc;u cầu của bạn. Trước khi sử dụng dịch vụ của S&agrave;n thương
        mại điện tử Vev&eacute;, Kh&aacute;ch h&agrave;ng cần đọc v&agrave; chấp
        thuận điều khoản, điều kiện, quy định, ch&iacute;nh s&aacute;ch hoặc thủ
        tục được quy định trong&nbsp;
      </p>
      <p>
        Điều khoản sử dụng n&agrave;y. Nếu như Kh&aacute;ch h&agrave;ng
        kh&ocirc;ng đồng &yacute; với những điều khoản, điều kiện, quy định,
        ch&iacute;nh s&aacute;ch hoặc thủ tục được n&ecirc;u ra dưới đ&acirc;y,
        vui l&ograve;ng kh&ocirc;ng sử dụng dịch vụ của ch&uacute;ng t&ocirc;i.
        Điều khoản sử dụng c&oacute; thể được sửa đổi theo như quy định tại mục
        2dưới đ&acirc;y.
      </p>
      <p>
        <strong>2. Sự Sửa Đổi Bổ Sung Điều Khoản Sử Dụng</strong>
      </p>
      <p>
        Vev&eacute; bảo lưu quyền chỉnh sửa, sửa đổi v&agrave; bổ sung bất kỳ
        nội dung n&agrave;o của Điều khoản sử dụng tại bất kỳ thời điểm
        n&agrave;o nếu x&eacute;t thấy cần thiết. C&aacute;c chỉnh sửa, sửa đổi
        v&agrave; bổ sung sẽ c&oacute; hiệu lực ngay khi được đăng tải
        tr&ecirc;n S&agrave;n thương mại điện tử Vev&eacute;. Kh&aacute;ch
        h&agrave;ng đồng &yacute; rằng sẽ thường xuy&ecirc;n cập nhật c&aacute;c
        Điều khoản sử dụng v&agrave; mọi sửa đổi của Điều khoản sử dụng
        n&agrave;y sẽ cấu th&agrave;nh sự đồng &yacute; v&agrave; chấp thuận của
        Kh&aacute;ch h&agrave;ng đối với c&aacute;c sửa đổi n&agrave;y d&ugrave;
        rằng đ&atilde; được cập bởi Kh&aacute;ch h&agrave;ng hay chưa. Việc tiếp
        tục sử dụng Dịch vụ của ch&uacute;ng t&ocirc;i đồng nghĩa Kh&aacute;ch
        h&agrave;ng đang đồng &yacute; với những điều khoản sửa đổi.
      </p>
      <p>
        <strong>
          3. Hướng Dẫn Sử Dụng Dịch Vụ S&agrave;n Thương Mại Điện Tử Vev&eacute;
        </strong>
      </p>
      <p>
        Khi truy cập v&agrave;o S&agrave;n thương mại điện tử của ch&uacute;ng
        t&ocirc;i (ứng dụng di động), Kh&aacute;ch h&agrave;ng phải đảm bảo đủ
        18 tuổi, hoặc truy cập dưới sự gi&aacute;m s&aacute;t của cha mẹ hay
        người gi&aacute;m hộ hợp ph&aacute;p. Kh&aacute;ch h&agrave;ng đảm bảo
        c&oacute; đầy đủ h&agrave;nh vi d&acirc;n sự để thực hiện c&aacute;c
        giao dịch mua b&aacute;n h&agrave;ng h&oacute;a theo quy định hiện
        h&agrave;nh của ph&aacute;p luật Việt Nam. Ch&uacute;ng t&ocirc;i sẽ cấp
        một t&agrave;i khoản (Account) sử dụng để kh&aacute;ch h&agrave;ng
        c&oacute; thể mua sắm tr&ecirc;n S&agrave;n thương mại điện tử
        Vev&eacute; trong khu&ocirc;n khổ Điều khoản sử dụng n&agrave;y.
      </p>
      <p>
        Qu&yacute; kh&aacute;ch h&agrave;ng sẽ phải đăng k&yacute; t&agrave;i
        khoản với th&ocirc;ng tin x&aacute;c thực về bản th&acirc;n v&agrave;
        phải cập nhật nếu c&oacute; bất kỳ thay đổi n&agrave;o. Mỗi người truy
        cập phải c&oacute; tr&aacute;ch nhiệm với mật khẩu, t&agrave;i khoản
        v&agrave; hoạt động của m&igrave;nh tr&ecirc;n S&agrave;n thương mại
        điện tử Vev&eacute;. Hơn nữa, qu&yacute; kh&aacute;ch h&agrave;ng phải
        th&ocirc;ng b&aacute;o cho ch&uacute;ng t&ocirc;i biết khi t&agrave;i
        khoản bị truy cập tr&aacute;i ph&eacute;p. Ch&uacute;ng t&ocirc;i
        kh&ocirc;ng chịu bất kỳ tr&aacute;ch nhiệm n&agrave;o, d&ugrave; trực
        tiếp hay gi&aacute;n tiếp, đối với những thiệt hại hoặc mất m&aacute;t
        g&acirc;y ra do qu&yacute; kh&aacute;ch kh&ocirc;ng tu&acirc;n thủ quy
        định.
      </p>
      <p>
        Nghi&ecirc;m cấm sử dụng bất kỳ phần n&agrave;o của ứng dụng n&agrave;y
        với mục đ&iacute;ch thương mại hoặc nh&acirc;n danh bất kỳ đối
        t&aacute;c thứ ba n&agrave;o nếu kh&ocirc;ng được ch&uacute;ng t&ocirc;i
        cho ph&eacute;p bằng văn bản. Nếu vi phạm bất cứ điều n&agrave;o trong
        đ&acirc;y, ch&uacute;ng t&ocirc;i sẽ hủy t&agrave;i khoản của
        kh&aacute;ch m&agrave; kh&ocirc;ng cần b&aacute;o trước.
      </p>
      <p>
        Trong suốt qu&aacute; tr&igrave;nh đăng k&yacute;, qu&yacute;
        kh&aacute;ch đồng &yacute; nhận email quảng c&aacute;o từ ứng dụng. Nếu
        kh&ocirc;ng muốn tiếp tục nhận mail, qu&yacute; kh&aacute;ch c&oacute;
        thể từ chối bằng c&aacute;ch nhấp v&agrave;o đường link ở dưới
        c&ugrave;ng trong mọi email quảng c&aacute;o.
      </p>
      <p>
        <strong>4. &Yacute; Kiến Của Kh&aacute;ch H&agrave;ng</strong>
      </p>
      <p>
        Tất cả nội dung trang ứng dụng v&agrave; &yacute; kiến ph&ecirc;
        b&igrave;nh của qu&yacute; kh&aacute;ch đều l&agrave; t&agrave;i sản của
        ch&uacute;ng t&ocirc;i. Nếu ch&uacute;ng t&ocirc;i ph&aacute;t hiện bất
        kỳ th&ocirc;ng tin giả mạo n&agrave;o, ch&uacute;ng t&ocirc;i sẽ
        kh&oacute;a t&agrave;i khoản của qu&yacute; kh&aacute;ch ngay lập tức
        hoặc &aacute;p dụng c&aacute;c biện ph&aacute;p kh&aacute;c theo quy
        định của Ph&aacute;p Luật Việt Nam.
      </p>
      <p>
        <strong>5. Chấp Nhận Đơn H&agrave;ng V&agrave; Gi&aacute; Cả</strong>
      </p>
      <p>
        Ch&uacute;ng t&ocirc;i c&oacute; quyền từ chối hoặc hủy đơn h&agrave;ng
        của qu&yacute; kh&aacute;ch v&igrave; bất kỳ l&yacute; do g&igrave;
        li&ecirc;n quan đến lỗi kỹ thuật, hệ thống một c&aacute;ch kh&aacute;ch
        quan v&agrave;o bất kỳ l&uacute;c n&agrave;o. Ngo&agrave;i ra, để đảm
        bảo t&iacute;nh c&ocirc;ng bằng cho kh&aacute;ch h&agrave;ng l&agrave;
        người ti&ecirc;u d&ugrave;ng cuối c&ugrave;ng của Vev&eacute;,
        ch&uacute;ng t&ocirc;i cũng sẽ từ chối c&aacute;c đơn h&agrave;ng
        kh&ocirc;ng nhằm mục đ&iacute;ch sử dụng cho c&aacute; nh&acirc;n, mua
        h&agrave;ng số lượng nhiều hoặc với mục đ&iacute;ch mua đi b&aacute;n
        lại.
      </p>
      <p>
        Ch&uacute;ng t&ocirc;i cam kết sẽ cung cấp th&ocirc;ng tin gi&aacute; cả
        ch&iacute;nh x&aacute;c nhất cho người ti&ecirc;u d&ugrave;ng. Tuy
        nhi&ecirc;n, đ&ocirc;i l&uacute;c vẫn c&oacute; sai s&oacute;t xảy ra,
        v&iacute; dụ như trường hợp gi&aacute; kh&ocirc;ng hiển thị ch&iacute;nh
        x&aacute;c tr&ecirc;n trang web/ứng dụng hoặc sai gi&aacute;, t&ugrave;y
        theo từng trường hợp ch&uacute;ng t&ocirc;i sẽ li&ecirc;n hệ hướng dẫn
        hoặc th&ocirc;ng b&aacute;o về đơn h&agrave;ng đ&oacute; cho qu&yacute;
        kh&aacute;ch. Ch&uacute;ng t&ocirc;i cũng c&oacute; quyền từ chối hoặc
        hủy bỏ bất kỳ đơn h&agrave;ng n&agrave;o d&ugrave; đơn h&agrave;ng
        đ&oacute; đ&atilde; hay chưa được x&aacute;c nhận hoặc đ&atilde; thanh
        to&aacute;n.
      </p>
      <p>
        <strong>
          6. C&aacute;c điều khoản về mua v&eacute; tr&ecirc;n Vev&eacute;
        </strong>
      </p>
      <p>
        V&eacute; đ&atilde; được mua kh&ocirc;ng được thay đổi hoặc hủy bỏ
        v&igrave; bất kỳ l&yacute; do n&agrave;o, c&oacute; thể được mua
        b&aacute;n, trao đổi th&ocirc;ng qua chức năng &ldquo;Chuyển
        v&eacute;&rdquo; của ứng dụng. Mọi h&igrave;nh thức trao đổi kh&aacute;c
        kh&ocirc;ng th&ocirc;ng qua ứng dụng c&oacute; thể dẫn đến việc
        qu&yacute; kh&aacute;ch h&agrave;ng bị từ chối tham dự sự kiện hoặc
        kho&aacute; t&agrave;i khoản. Kh&aacute;ch h&agrave;ng sẽ nhận được
        V&eacute; sau khi giao dịch được coi l&agrave; ho&agrave;n th&agrave;nh
        v&agrave; nhận được x&aacute;c nhận trong ứng dụng Vev&eacute;
        tr&ecirc;n điện thoại di động
      </p>
      <p>
        Nh&agrave; tổ chức sự kiện c&oacute; quyền y&ecirc;u cầu Kh&aacute;ch
        h&agrave;ng cung cấp c&aacute;c th&ocirc;ng tin để chứng minh v&eacute;
        hợp lệ bao gồm: V&eacute;, Th&ocirc;ng tin đơn h&agrave;ng của
        Kh&aacute;ch h&agrave;ng đối với sự kiện của Nh&agrave; tổ chức.
      </p>
      <p>
        Ch&uacute;ng t&ocirc;i c&oacute; quyền tự động hủy bỏ v&eacute;
        m&agrave; kh&ocirc;ng cần bồi thường nếu Kh&aacute;ch h&agrave;ng
        h&agrave;ng thực hiện h&agrave;nh vi b&aacute;n lại, giả mạo hoặc
        l&agrave;m nhiều bản sao v&eacute;. Vev&eacute; sẽ kh&ocirc;ng chịu
        tr&aacute;ch nhiệm đối với c&aacute;c v&eacute; được mua tại bất kỳ một
        nguồn n&agrave;o kh&aacute;c m&agrave; kh&ocirc;ng phải l&agrave;
        tr&ecirc;n S&agrave;n thương mại điện tử Vev&eacute; v&agrave;
        Vev&eacute; c&oacute; quyền từ chối cung cấp dịch vụ cho Kh&aacute;ch
        h&agrave;ng h&agrave;ng trong trường hợp n&agrave;y. Ngo&agrave;i ra,
        Vev&eacute; c&oacute; quyền hạn chế hoặc từ chối quyền mua v&eacute; cho
        bất cứ Kh&aacute;ch h&agrave;ng n&agrave;o m&agrave; ch&uacute;ng
        t&ocirc;i x&aacute;c định được đ&atilde; vi phạm ch&iacute;nh
        s&aacute;ch của ch&uacute;ng t&ocirc;i. Ch&uacute;ng t&ocirc;i
        kh&ocirc;ng đảm bảo t&iacute;nh x&aacute;c thực của v&eacute; mua từ bất
        kỳ đại l&yacute; b&aacute;n lẻ b&ecirc;n thứ ba kh&ocirc;ng được ủy
        quyền (chẳng hạn như c&aacute;c nh&agrave; m&ocirc;i giới c&aacute;
        nh&acirc;n),
      </p>
      <p>
        Vev&eacute; chỉ chấp nhận c&aacute;c v&eacute; được mua v&eacute; trực
        tiếp từ ứng dụng Vev&eacute; v&agrave; c&aacute;c đối t&aacute;c được ủy
        quyền để đảm bảo t&iacute;nh x&aacute;c thực v&eacute;.
      </p>
      <p>
        V&eacute; được mua tr&ecirc;n S&agrave;n thương mại điện tử Vev&eacute;
        sẽ kh&ocirc;ng được sử dụng cho mục đ&iacute;ch quảng c&aacute;o, khuyến
        m&atilde;i, r&uacute;t thăm tr&uacute;ng thưởng cho c&aacute;c cuộc thi
        hoặc, trừ khi văn bản uỷ quyền ch&iacute;nh thức được đưa ra bởi
        Vev&eacute;, với điều kiện l&agrave; ngay cả khi c&oacute; sự đồng
        &yacute; như vậy l&agrave; được, bất kỳ việc sử dụng nh&atilde;n hiệu
        Vev&eacute; v&agrave; bất kỳ quyền sở hữu tr&iacute; tuệ n&agrave;o đối
        với t&agrave;i sản của Vev&eacute; cần sự chấp thuận bằng văn bản của
        ch&uacute;ng t&ocirc;i.
      </p>
      <p>
        <strong>7. Hủy bỏ giao dịch, ho&agrave;n tiền tại Vev&eacute;</strong>
      </p>
      <p>
        Việc đổi trả, ho&agrave;n tiền sẽ được quy định bởi Ban tổ chức sự kiện
        cho mỗi sự kiện cụ thể. Trường hợp sự kiện gặp sự cố dời ng&agrave;y tổ
        chức hoặc sự kiện bị hủy m&agrave; do l&yacute; do từ Nh&agrave; tổ chức
        sự kiện, Người tham gia sự kiện c&oacute; thể y&ecirc;u cầu ho&agrave;n
        trả v&eacute; v&agrave; ho&agrave;n tiền. Việc ho&agrave;n trả v&eacute;
        v&agrave; ho&agrave;n tiền sẽ do Nh&agrave; tổ chức sự kiện quyết định.
        Kh&aacute;ch h&agrave;ng vui l&ograve;ng li&ecirc;n hệ Vev&eacute; để
        được hỗ trợ ho&agrave;n lại tiền đối với sự kiện bị hủy, tr&igrave;
        ho&atilde;n theo th&ocirc;ng tin dưới
      </p>
      <p>đ&acirc;y:</p>
      <p>Email: hi@veve.com.vn</p>
      <p>
        <strong>8. Quyền sở hữu tr&iacute; tuệ</strong>
      </p>
      <p>
        Vev&eacute; v&agrave;/hoặc C&ocirc;ng ty Li&ecirc;n Kết Vev&eacute; sẽ
        l&agrave; chủ sở hữu c&aacute;c quyền, quyền lợi v&agrave; lợi
        &iacute;ch đối với quyền sở hữu tr&iacute; tuệ của m&igrave;nh trong
        v&agrave; đối với tất cả c&aacute;c phi&ecirc;n bản của S&agrave;n
        thương mại điện tử Vev&eacute; v&agrave; t&agrave;i liệu li&ecirc;n
        quan.
      </p>
      <p>
        Nh&agrave; tổ chức thừa nhận v&agrave; đồng &yacute; rằng dữ liệu
        kh&aacute;ch h&agrave;ng do Vev&eacute; thu thập theo sự đồng &yacute;
        của kh&aacute;ch h&agrave;ng v&agrave; theo quy định của ph&aacute;p
        luật sẽ được th&ocirc;ng tin độc quyền của Vev&eacute;, v&agrave; tất cả
        quyền, quyền lợi, lợi &iacute;ch v&agrave; đối với dữ liệu kh&aacute;ch
        h&agrave;ng thuộc sở hữu của Vev&eacute;.
      </p>
      <p>
        Nh&agrave; tổ chức cam kết v&agrave; đồng &yacute; rằng Nh&agrave; tổ
        chức l&agrave; chủ sở hữu hoặc c&oacute; quyền hợp ph&aacute;p đối với
        Quyền Sở Hữu Tr&iacute; Tuệ về sự kiện bao gồm nhưng kh&ocirc;ng giới
        hạn h&igrave;nh ảnh, video, bản ghi &acirc;m, ghi h&igrave;nh, từ ngữ,
        nội dung v&agrave; quyền của b&ecirc;n thứ ba li&ecirc;n quan đến sự
        kiện cung cấp cho Vev&eacute; theo Điều khoản sử dụng n&agrave;y hoặc
        trong qu&aacute; tr&igrave;nh thực hiện Điều khoản sử dụng n&agrave;y.
      </p>
      <p>
        Nh&agrave; tổ chức x&aacute;c nhận v&agrave; đồng &yacute; rằng
        Vev&eacute; sở hữu duy nhất v&agrave; v&ocirc; thời hạn đối với tất cả
        th&ocirc;ng tin kh&aacute;ch h&agrave;ng, mọi kết quả c&ocirc;ng việc
        được tạo ra bởi Vev&eacute; hoặc theo hướng dẫn hoặc được giao bởi
        Vev&eacute;, quyền t&aacute;c giả v&agrave; quyền li&ecirc;n quan
        v&agrave; c&aacute;c quyền sở hữu c&ocirc;ng nghiệp kh&aacute;c
        ph&aacute;t sinh từ li&ecirc;n quan đến Điều khoản sử dụng n&agrave;y
        d&ugrave; đ&atilde; được đăng k&yacute; bảo hộ hay chưa, được thu thập
        th&ocirc;ng qua hoặc từ S&agrave;n thương mại điện tử Vev&eacute;
        (&ldquo;Sở Hữu Tr&iacute; Tuệ của Vev&eacute;&rdquo;). Nh&agrave; tổ
        chức cam kết rằng kh&ocirc;ng sử dụng Sở Hữu Tr&iacute; Tuệ của
        Vev&eacute; khi chưa c&oacute; sự cho ph&eacute;p trước bằng văn bản của
        Vev&eacute;. Nh&agrave; tổ chức phải thực hiện tất cả c&aacute;c bước
        được y&ecirc;u cầu một c&aacute;ch hợp l&yacute; để hỗ trợ Vev&eacute;
        thực hiện c&aacute;c thủ tục ph&aacute;p l&yacute; cần thiết để thực thi
        để ho&agrave;n thiện, đăng k&yacute; hoặc thi h&agrave;nh c&aacute;c
        quyền sở hữu n&oacute;i tr&ecirc;n, to&agrave;n bộ hoặc một phần.
        Nh&agrave; tổ chức cam kết kh&ocirc;ng quyền sao ch&eacute;p, dịch,
        th&aacute;o rời, dịch ngược hoặc đảo ngược bất kỳ Sở Hữu Tr&iacute; Tuệ
        của Vev&eacute;. Nh&agrave; tổ chức kh&ocirc;ng được tạo hoặc cố gắng
        tạo m&atilde; nguồn từ m&atilde; đối tượng của phần mềm hoặc c&aacute;c
        t&agrave;i liệu Sở Hữu Tr&iacute; Tuệ của Vev&eacute;. Nh&agrave; tổ
        chức kh&ocirc;ng được thay đổi hoặc x&oacute;a c&aacute;c th&ocirc;ng
        b&aacute;o về Sở Hữu Tr&iacute; Tuệ của Vev&eacute; tr&ecirc;n
        S&agrave;n thương mại điện tử Vev&eacute;.
      </p>
      <p>
        <strong>9. Quyền ph&aacute;p l&yacute;</strong>
      </p>
      <p>
        C&aacute;c điều kiện, điều khoản v&agrave; nội dung của Điều khoản sử
        dụng n&agrave;y được điều chỉnh theo ph&aacute;p luật Việt Nam v&agrave;
        T&ograve;a &aacute;n c&oacute; thẩm quyền tại Việt Nam sẽ giải quyết bất
        kỳ tranh chấp n&agrave;o ph&aacute;t sinh từ việc sử dụng tr&aacute;i
        ph&eacute;p trang S&agrave;n thương mại điện tử Vev&eacute;.
      </p>
      <p>
        <strong>10. Quy định về bảo mật</strong>
      </p>
      <p>
        S&agrave;n thương mại điện tử Vev&eacute; của ch&uacute;ng t&ocirc;i coi
        trọng việc bảo mật th&ocirc;ng tin v&agrave; sử dụng c&aacute;c biện
        ph&aacute;p tốt nhất bảo vệ th&ocirc;ng tin v&agrave; việc thanh
        to&aacute;n của qu&yacute; kh&aacute;ch. Th&ocirc;ng tin của qu&yacute;
        kh&aacute;ch trong qu&aacute; tr&igrave;nh thanh to&aacute;n sẽ được
        m&atilde; h&oacute;a để đảm bảo an to&agrave;n. Sau khi qu&yacute;
        kh&aacute;ch ho&agrave;n th&agrave;nh qu&aacute; tr&igrave;nh đặt
        h&agrave;ng, qu&yacute; kh&aacute;ch sẽ tho&aacute;t khỏi chế độ an
        to&agrave;n.
      </p>
      <p>
        Qu&yacute; kh&aacute;ch kh&ocirc;ng được sử dụng bất kỳ chương
        tr&igrave;nh, c&ocirc;ng cụ hay h&igrave;nh thức n&agrave;o kh&aacute;c
        để can thiệp v&agrave;o hệ thống hay l&agrave;m thay đổi cấu tr&uacute;c
        dữ liệu. S&agrave;n thương mại điện tử Vev&eacute; cũng nghi&ecirc;m cấm
        việc ph&aacute;t t&aacute;n, truyền b&aacute; hay cổ vũ cho bất kỳ hoạt
        động n&agrave;o nhằm can thiệp, ph&aacute; hoại hay x&acirc;m nhập
        v&agrave;o dữ liệu của hệ thống. C&aacute; nh&acirc;n hay tổ chức vi
        phạm sẽ bị tước bỏ mọi quyền lợi cũng như sẽ bị truy tố trước
        Ph&aacute;p Luật nếu cần thiết.
      </p>
      <p>
        Mọi th&ocirc;ng tin giao dịch sẽ được bảo mật ngoại trừ trong trường hợp
        cơ quan Ph&aacute;p Luật y&ecirc;u cầu.
      </p>
      <p>
        <strong>
          11. Thanh to&aacute;n an to&agrave;n v&agrave; tiện lợi tại
          Vev&eacute;
        </strong>
      </p>
      <p>Thanh to&aacute;n qua v&iacute; điện tử MoMo:</p>
      <p>
        Bước 1: Người mua t&igrave;m hiểu th&ocirc;ng tin về sự kiện v&agrave;
        nh&agrave; tổ chức sự kiện đ&oacute;;
      </p>
      <p>
        Bước 2: Người mua x&aacute;c thực đơn h&agrave;ng (điện thoại, tin nhắn,
        email);
      </p>
      <p>Bước 3: Người b&aacute;n x&aacute;c nhận th&ocirc;ng tin Người mua;</p>
      <p>
        Bước 4: Người mua đặt mua sản phẩm, lựa chọn phương thức thanh
        to&aacute;n bằng v&iacute; điện tử
      </p>
      <p>
        MoMo v&agrave; thanh to&aacute;n. Kh&aacute;ch h&agrave;ng cần tải ứng
        dụng V&iacute; điện tử MoMo về điện thoại, li&ecirc;n
      </p>
      <p>
        kết với ng&acirc;n h&agrave;ng v&agrave; nạp tiền v&agrave;o v&iacute;
        MoMo;
      </p>
      <p>Bước 5: Người b&aacute;n chuyển v&eacute;;</p>
      <p>Bước 6: Người mua nhận v&eacute;.</p>
      <p>
        <strong>12. Đảm bảo an to&agrave;n giao dịch tại Vev&eacute;</strong>
      </p>
      <p>
        Ch&uacute;ng t&ocirc;i sử dụng c&aacute;c dịch vụ để bảo vệ th&ocirc;ng
        tin về nội dung m&agrave; người b&aacute;n đăng sản phẩm tr&ecirc;n
        Vev&eacute;. Để đảm bảo c&aacute;c giao dịch được tiến h&agrave;nh
        th&agrave;nh c&ocirc;ng, hạn chế tối đa rủi ro c&oacute; thể ph&aacute;t
        sinh.
      </p>
      <p>
        <strong>
          13. Th&ocirc;ng tin c&aacute; nh&acirc;n v&agrave; t&iacute;nh
          ri&ecirc;ng tư
        </strong>
      </p>
      <p>
        Ngo&agrave;i hệ thống lưu trữ dữ liệu của ri&ecirc;ng m&igrave;nh,
        ch&uacute;ng t&ocirc;i c&oacute; thể sử dụng một số dịch vụ của
        b&ecirc;n thứ 3 để thu thập c&aacute;c th&ocirc;ng tin (đ&atilde; được
        m&atilde; ho&aacute; nặc danh) khi bạn sử dụng dịch vụ của Vev&eacute;
        tr&ecirc;n Vev&eacute; App. Việc thu thập th&ocirc;ng tin n&agrave;y
        gi&uacute;p ch&uacute;ng t&ocirc;i c&aacute; nh&acirc;n ho&aacute; trải
        nghiệm của bạn cải tiến dịch vụ của ch&uacute;ng t&ocirc;i v&agrave;
        mang đến những th&ocirc;ng điệp quảng c&aacute;o ph&ugrave; hợp với nhu
        cầu mua sắm của bạn.
      </p>
      <p>
        C&aacute;c th&ocirc;ng tin thu thập được bạn chủ động gửi cho
        ch&uacute;ng t&ocirc;i khi đăng k&yacute; t&agrave;i khoản tr&ecirc;n
      </p>
      <p>Vev&eacute;:</p>
      <p>
        &bull; T&ecirc;n họ, địa chỉ, số điện thoại, email, ng&agrave;y sinh,
        giới t&iacute;nh
      </p>
      <p>
        &bull; C&aacute;c th&ocirc;ng tin đăng k&yacute; kh&aacute;c khi bạn chủ
        động tham gia c&aacute;c chương tr&igrave;nh c&oacute; thu thập
        th&ocirc;ng tin n&agrave;y
      </p>
      <p>
        &bull; Để sử dụng c&aacute;c t&iacute;nh năng như t&igrave;m bằng
        h&igrave;nh ảnh, ch&uacute;ng t&ocirc;i cũng dựa v&agrave;o sự cho
      </p>
      <p>
        ph&eacute;p của bạn khi bạn muốn gửi h&igrave;nh cho ch&uacute;ng
        t&ocirc;i
      </p>
      <p>
        &bull; C&aacute;c th&ocirc;ng tin thu thập tự động khi bạn sử dụng dịch
        vụ của ch&uacute;ng t&ocirc;i tr&ecirc;n Vev&eacute; App, ngo&agrave;i
        hệ thống lưu trữ ri&ecirc;ng, c&aacute;c th&ocirc;ng tin n&agrave;y cũng
        được ghi nhận bởi c&aacute;c dịch vụ truy vấn dữ liệu kh&aacute;c bao
        gồm Facebook, Google, v&agrave; Firebase:
      </p>
      <p>&bull; Lịch sử mua h&agrave;ng</p>
      <p>&bull; Lịch sử thao t&aacute;c người d&ugrave;ng</p>
      <p>
        &bull; Th&ocirc;ng tin thanh to&aacute;n online được bảo mật v&agrave;
        ghi nhận bởi c&aacute;c b&ecirc;n dưới đ&acirc;y khi bạn thanh
        to&aacute;n trực tuyến tr&ecirc;n Vev&eacute; App:
      </p>
      <p>
        &bull; Cybersource: Hệ thống quốc tế về bảo mật thanh to&aacute;n thẻ
        t&iacute;n dụng, ghi nợ (chuẩn
      </p>
      <p>PCI DSS)</p>
      <p>&bull; Momo: V&iacute; điện tử (chuẩn PCI DSS)</p>
    </Policy>
  );
};
