/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiBell1 = ({ className }) => {
  return (
    <svg
      className={`li-bell-1 ${className}`}
      fill="none"
      height="11"
      viewBox="0 0 11 11"
      width="11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_448_1469)">
        <path
          className="path"
          clipRule="evenodd"
          d="M4.29499 1.84809C4.82736 1.48179 5.48343 1.34198 6.11888 1.45941C6.75433 1.57684 7.3171 1.94189 7.6834 2.47426C8.04969 3.00663 8.1895 3.6627 8.07207 4.29815C7.86 5.4457 7.97375 6.20368 8.12331 6.66015C8.1984 6.88935 8.28374 7.04637 8.34558 7.14165C8.37656 7.1894 8.40184 7.22194 8.41725 7.24048C8.42496 7.24976 8.43021 7.25555 8.43248 7.25799C8.43283 7.25838 8.43311 7.25868 8.43332 7.2589C8.54045 7.36586 8.56626 7.53007 8.49662 7.66491C8.42623 7.80119 8.27493 7.87534 8.1241 7.84746L1.96305 6.7089C1.81221 6.68103 1.69741 6.55771 1.68038 6.40527C1.66353 6.25445 1.74632 6.11031 1.8846 6.0487C1.88487 6.04857 1.88524 6.04839 1.88571 6.04816C1.88871 6.04668 1.89568 6.04315 1.90619 6.03724C1.92721 6.02543 1.96244 6.00408 2.00845 5.97055C2.10025 5.90366 2.23608 5.78751 2.38812 5.60029C2.69094 5.22742 3.06807 4.56015 3.28014 3.4126C3.39757 2.77715 3.76262 2.21438 4.29499 1.84809ZM1.88849 6.04699C1.88852 6.04698 1.88855 6.04696 1.88858 6.04695C1.88858 6.04695 1.88858 6.04695 1.88858 6.04695L1.88849 6.04699ZM2.82523 6.16029L7.51493 7.02695C7.49678 6.97946 7.47898 6.92946 7.46176 6.8769C7.27184 6.29722 7.1568 5.42006 7.38751 4.17164C7.47139 3.71775 7.37152 3.24913 7.10989 2.86887C6.84825 2.4886 6.44626 2.22785 5.99237 2.14397C5.53848 2.06009 5.06986 2.15996 4.68959 2.4216C4.30933 2.68324 4.04858 3.08522 3.9647 3.53911C3.734 4.78752 3.31307 5.56565 2.92852 6.03916C2.89365 6.0821 2.85915 6.12243 2.82523 6.16029Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M4.14458 7.8678C4.32563 7.80316 4.52479 7.89753 4.58943 8.07858C4.60993 8.13601 4.64516 8.18704 4.69159 8.22657C4.73802 8.2661 4.79402 8.29274 4.85399 8.30382C4.91395 8.31491 4.97577 8.31004 5.03327 8.28971C5.09076 8.26939 5.1419 8.23431 5.18157 8.188C5.30665 8.04202 5.52638 8.02506 5.67237 8.15013C5.81836 8.2752 5.83531 8.49494 5.71024 8.64093C5.59122 8.77985 5.43779 8.88507 5.26532 8.94605C5.09284 9.00703 4.90737 9.02163 4.72748 8.98839C4.54759 8.95514 4.37959 8.87522 4.2403 8.75663C4.10101 8.63804 3.99531 8.48493 3.93381 8.31264C3.86917 8.1316 3.96354 7.93243 4.14458 7.8678Z"
          fill="#636073"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_448_1469">
          <rect
            className="rect"
            fill="white"
            height="8.35383"
            transform="translate(2.07471 0.358093) rotate(10.4701)"
            width="8.35383"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
