/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiTicket = ({ className }) => {
  return (
    <svg
      className={`li-ticket ${className}`}
      fill="none"
      height="11"
      viewBox="0 0 11 11"
      width="11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_448_1567)">
        <path
          className="path"
          clipRule="evenodd"
          d="M2.59548 4.59377C2.50792 4.61646 2.43296 4.673 2.38709 4.75095C2.34122 4.82891 2.32819 4.9219 2.35088 5.00946L2.52197 5.66974C2.56922 5.85207 2.45971 6.03818 2.27738 6.08542C2.09505 6.13267 1.90894 6.02316 1.86169 5.84083L1.6906 5.18055C1.62253 4.91787 1.6616 4.63891 1.79922 4.40504C1.93683 4.17118 2.16171 4.00155 2.42438 3.93349L7.04636 2.73584C7.30903 2.66777 7.58799 2.70684 7.82186 2.84446C8.05573 2.98207 8.22536 3.20695 8.29342 3.46962L8.46451 4.12991C8.51176 4.31224 8.40225 4.49835 8.21992 4.54559C8.03759 4.59284 7.85148 4.48333 7.80423 4.301L7.63314 3.64072C7.61045 3.55316 7.55391 3.4782 7.47595 3.43233C7.39799 3.38646 7.30501 3.37343 7.21745 3.39612L2.59548 4.59377ZM2.61956 7.40599C2.8019 7.35874 2.988 7.46825 3.03525 7.65058L3.20634 8.31087C3.22903 8.39842 3.28557 8.47338 3.36353 8.51925C3.44149 8.56512 3.53447 8.57815 3.62203 8.55546L8.24401 7.35781C8.33156 7.33513 8.40652 7.27858 8.45239 7.20063C8.49826 7.12267 8.51129 7.02968 8.4886 6.94213L8.31751 6.28184C8.27026 6.09951 8.37977 5.9134 8.5621 5.86616C8.74443 5.81891 8.93054 5.92842 8.97779 6.11075L9.14888 6.77103C9.21695 7.03371 9.17788 7.31267 9.04027 7.54654C8.90265 7.78041 8.67777 7.95003 8.4151 8.0181L3.79312 9.21574C3.53045 9.28381 3.25149 9.24474 3.01762 9.10713C2.78375 8.96951 2.61413 8.74463 2.54606 8.48196L2.37497 7.82168C2.32772 7.63934 2.43723 7.45323 2.61956 7.40599Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M8.21993 4.54558C8.04481 4.59095 7.89489 4.70404 7.80315 4.85995C7.71141 5.01586 7.68536 5.20183 7.73074 5.37695C7.77612 5.55207 7.8892 5.70199 8.04511 5.79373C8.20102 5.88547 8.387 5.91152 8.56211 5.86614C8.74445 5.81889 8.93055 5.9284 8.9778 6.11073C9.02505 6.29307 8.91554 6.47918 8.73321 6.52642C8.38297 6.61717 8.01103 6.56508 7.6992 6.3816C7.38738 6.19812 7.16121 5.89828 7.07046 5.54804C6.9797 5.19781 7.0318 4.82586 7.21528 4.51404C7.39876 4.20221 7.6986 3.97605 8.04884 3.88529C8.23117 3.83805 8.41728 3.94756 8.46452 4.12989C8.51177 4.31222 8.40226 4.49833 8.21993 4.54558Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M1.86167 5.84082C1.81442 5.65849 1.92393 5.47238 2.10626 5.42513C2.4565 5.33438 2.82844 5.38648 3.14027 5.56996C3.45209 5.75344 3.67826 6.05328 3.76901 6.40351C3.85977 6.75375 3.80767 7.12569 3.62419 7.43752C3.44071 7.74934 3.14087 7.97551 2.79063 8.06626C2.6083 8.11351 2.42219 8.004 2.37495 7.82167C2.3277 7.63934 2.43721 7.45323 2.61954 7.40598C2.79466 7.3606 2.94458 7.24752 3.03632 7.09161C3.12806 6.9357 3.15411 6.74972 3.10873 6.57461C3.06335 6.39949 2.95027 6.24957 2.79436 6.15783C2.63845 6.06609 2.45247 6.04004 2.27736 6.08542C2.09502 6.13266 1.90892 6.02315 1.86167 5.84082Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M5.06561 3.24911C5.24795 3.20186 5.43406 3.31137 5.4813 3.4937L5.65239 4.15399C5.69964 4.33632 5.59013 4.52243 5.4078 4.56967C5.22547 4.61692 5.03936 4.50741 4.99211 4.32508L4.82102 3.6648C4.77377 3.48246 4.88328 3.29636 5.06561 3.24911Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M6.0921 7.21081C6.27444 7.16356 6.46055 7.27307 6.50779 7.4554L6.67888 8.11569C6.72613 8.29802 6.61662 8.48413 6.43429 8.53137C6.25196 8.57862 6.06585 8.46911 6.0186 8.28678L5.84751 7.6265C5.80026 7.44417 5.90977 7.25806 6.0921 7.21081Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M5.5788 5.22994C5.76113 5.1827 5.94724 5.29221 5.99449 5.47454L6.16558 6.13482C6.21282 6.31715 6.10332 6.50326 5.92098 6.55051C5.73865 6.59775 5.55254 6.48825 5.5053 6.30591L5.3342 5.64563C5.28696 5.4633 5.39647 5.27719 5.5788 5.22994Z"
          fill="#636073"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_448_1567">
          <rect
            className="rect"
            fill="white"
            height="8.18507"
            transform="translate(0.431519 3.04065) rotate(-14.527)"
            width="8.18507"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
