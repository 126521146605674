/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiTicket = ({ className }) => {
  return (
    <svg
      className={`li-ticket ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_429_326)">
        <path
          className="path"
          clipRule="evenodd"
          d="M4.97706 8.40186C4.79304 8.44954 4.6355 8.56837 4.5391 8.73221C4.4427 8.89604 4.41533 9.09146 4.46301 9.27548L4.82258 10.6631C4.92187 11.0463 4.69173 11.4375 4.30853 11.5368C3.92534 11.6361 3.53421 11.4059 3.43491 11.0227L3.07534 9.63505C2.93229 9.083 3.0144 8.49674 3.30361 8.00523C3.59282 7.51373 4.06543 7.15724 4.61748 7.01419L14.3312 4.49718C14.8832 4.35413 15.4695 4.43625 15.961 4.72545C16.4525 5.01466 16.809 5.48727 16.952 6.03932L17.3116 7.42699C17.4109 7.81019 17.1807 8.20132 16.7975 8.30061C16.4144 8.39991 16.0232 8.16976 15.9239 7.78656L15.5644 6.3989C15.5167 6.21488 15.3978 6.05734 15.234 5.96094C15.0702 5.86454 14.8747 5.83717 14.6907 5.88485L4.97706 8.40186ZM5.02768 14.3121C5.41087 14.2128 5.80201 14.443 5.9013 14.8262L6.26087 16.2138C6.30855 16.3978 6.42738 16.5554 6.59122 16.6518C6.75506 16.7482 6.95048 16.7755 7.13449 16.7279L16.8482 14.2109C17.0322 14.1632 17.1897 14.0443 17.2861 13.8805C17.3825 13.7167 17.4099 13.5213 17.3622 13.3372L17.0026 11.9496C16.9033 11.5664 17.1335 11.1752 17.5167 11.0759C17.8999 10.9767 18.291 11.2068 18.3903 11.59L18.7499 12.9777C18.8929 13.5297 18.8108 14.116 18.5216 14.6075C18.2324 15.099 17.7598 15.4555 17.2077 15.5985L7.49407 18.1155C6.94202 18.2586 6.35575 18.1765 5.86425 17.8873C5.37274 17.5981 5.01625 17.1254 4.8732 16.5734L4.51363 15.1857C4.41434 14.8025 4.64448 14.4114 5.02768 14.3121Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M16.7975 8.30061C16.4295 8.39598 16.1144 8.63364 15.9216 8.96131C15.7288 9.28898 15.6741 9.67982 15.7694 10.0479C15.8648 10.4159 16.1025 10.731 16.4301 10.9238C16.7578 11.1166 17.1487 11.1713 17.5167 11.0759C17.8999 10.9767 18.291 11.2068 18.3903 11.59C18.4896 11.9732 18.2594 12.3643 17.8763 12.4636C17.1402 12.6543 16.3585 12.5449 15.7032 12.1593C15.0478 11.7736 14.5725 11.1435 14.3818 10.4074C14.191 9.67136 14.3005 8.88968 14.6861 8.23434C15.0717 7.57899 15.7019 7.10367 16.438 6.91295C16.8212 6.81365 17.2123 7.0438 17.3116 7.42699C17.4109 7.81019 17.1807 8.20132 16.7975 8.30061Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M3.43491 11.0227C3.33562 10.6395 3.56577 10.2484 3.94896 10.1491C4.68503 9.95837 5.46671 10.0679 6.12205 10.4535C6.7774 10.8391 7.25271 11.4692 7.44344 12.2053C7.63417 12.9414 7.52469 13.723 7.13908 14.3784C6.75347 15.0337 6.12332 15.509 5.38725 15.6998C5.00406 15.7991 4.61293 15.5689 4.51363 15.1857C4.41434 14.8025 4.64449 14.4114 5.02768 14.3121C5.39571 14.2167 5.71079 13.9791 5.90359 13.6514C6.0964 13.3237 6.15114 12.9329 6.05577 12.5649C5.96041 12.1968 5.72275 11.8818 5.39508 11.6889C5.06741 11.4961 4.67657 11.4414 4.30853 11.5368C3.92534 11.6361 3.53421 11.4059 3.43491 11.0227Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M10.1682 5.5759C10.5514 5.4766 10.9425 5.70675 11.0418 6.08995L11.4014 7.47761C11.5007 7.86081 11.2705 8.25194 10.8873 8.35123C10.5041 8.45053 10.113 8.22038 10.0137 7.83719L9.65412 6.44952C9.55483 6.06632 9.78497 5.67519 10.1682 5.5759Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M12.3256 13.9019C12.7088 13.8026 13.0999 14.0328 13.1992 14.416L13.5588 15.8036C13.6581 16.1868 13.4279 16.578 13.0447 16.6772C12.6615 16.7765 12.2704 16.5464 12.1711 16.1632L11.8115 14.7755C11.7122 14.3923 11.9424 14.0012 12.3256 13.9019Z"
          fill="#636073"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M11.2469 9.7389C11.6301 9.63961 12.0212 9.86976 12.1205 10.253L12.4801 11.6406C12.5794 12.0238 12.3492 12.4149 11.966 12.5142C11.5828 12.6135 11.1917 12.3834 11.0924 12.0002L10.7328 10.6125C10.6335 10.2293 10.8637 9.8382 11.2469 9.7389Z"
          fill="#636073"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_429_326">
          <rect
            className="rect"
            fill="white"
            height="17.202"
            transform="translate(0.429169 5.13779) rotate(-14.527)"
            width="17.202"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
