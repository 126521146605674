import { Policy } from "../components/policy/screens/Frame/Policy";

export const PrivacyPolicy = () => {
  return (
    <Policy title={"Chính Sách Bảo Mật"}>
      <p style={{ textAlign: "center" }}>
        <strong>
          CH&Iacute;NH S&Aacute;CH BẢO MẬT TH&Ocirc;NG TIN C&Aacute; NH&Acirc;N
          CỦA NGƯỜI TI&Ecirc;U D&Ugrave;NG
        </strong>
      </p>
      <ol>
        <li>
          <strong>Sự chấp thuận</strong>
        </li>
      </ol>
      <p>
        Bằng việc trao cho ch&uacute;ng t&ocirc;i th&ocirc;ng tin c&aacute;
        nh&acirc;n của th&agrave;nh vi&ecirc;n, sử dụng STMĐT Vev&eacute;,
        th&agrave;nh vi&ecirc;n đồng &yacute; th&ocirc;ng tin c&aacute;
        nh&acirc;n của th&agrave;nh vi&ecirc;n sẽ được thu thập, sử dụng như
        được n&ecirc;u trong Ch&iacute;nh s&aacute;ch bảo mật th&ocirc;ng tin
        (&ldquo;Ch&iacute;nh S&aacute;ch&rdquo;). Nếu th&agrave;nh vi&ecirc;n
        kh&ocirc;ng đồng &yacute; với Ch&iacute;nh S&aacute;ch n&agrave;y,
        th&agrave;nh vi&ecirc;n dừng cung cấp cho ch&uacute;ng t&ocirc;i bất cứ
        th&ocirc;ng tin c&aacute; nh&acirc;n n&agrave;o v&agrave;/hoặc sử dụng
        c&aacute;c quyền như được n&ecirc;u tại Mục 7 dưới đ&acirc;y.
        Ch&uacute;ng t&ocirc;i bảo lưu quyền sửa đổi, bổ sung nhằm ho&agrave;n
        thiện đối với Ch&iacute;nh S&aacute;ch n&agrave;y v&agrave;o bất kỳ thời
        điểm n&agrave;o. Ch&uacute;ng t&ocirc;i khuyến kh&iacute;ch th&agrave;nh
        vi&ecirc;n thường xuy&ecirc;n xem lại Ch&iacute;nh S&aacute;ch Bảo Mật
        Th&ocirc;ng Tin n&agrave;y để c&oacute; được những cập nhật mới nhất đảm
        bảo th&agrave;nh vi&ecirc;n biết v&agrave; thực hiện quyền quản
        l&yacute; th&ocirc;ng tin c&aacute; nh&acirc;n của m&igrave;nh.
      </p>
      <ol>
        <li>
          <strong>Mục đ&iacute;ch th&ocirc;ng tin c&aacute; nh&acirc;n</strong>
        </li>
      </ol>
      <p>
        Ch&uacute;ng t&ocirc;i thu thập th&ocirc;ng tin c&aacute; nh&acirc;n chỉ
        cần thiết nhằm phục vụ cho c&aacute;c mục đ&iacute;ch:
      </p>
      <ol>
        <li>
          Đơn H&agrave;ng: để xử l&yacute; c&aacute;c vấn đề li&ecirc;n quan đến
          đơn đặt h&agrave;ng của th&agrave;nh vi&ecirc;n;
        </li>
        <li>
          Duy Tr&igrave; T&agrave;i Khoản: để tạo v&agrave; duy tr&igrave;nh
          t&agrave;i khoản của th&agrave;nh vi&ecirc;n với ch&uacute;ng
          t&ocirc;i, bao gồm cả c&aacute;c chương tr&igrave;nh kh&aacute;ch
          h&agrave;ng th&acirc;n thiết hoặc c&aacute;c chương tr&igrave;nh
          thưởng đi k&egrave;m với t&agrave;i khoản của th&agrave;nh vi&ecirc;n;
        </li>
        <li>
          Dịch Vụ Người Ti&ecirc;u D&ugrave;ng, Dịch Vụ Chăm S&oacute;c
          Kh&aacute;ch H&agrave;ng: bao gồm c&aacute;c phản hồi cho c&aacute;c
          y&ecirc;u cầu, khiếu nại v&agrave; phản hồi của th&agrave;nh
          vi&ecirc;n;
        </li>
        <li>
          C&aacute; Nh&acirc;n H&oacute;a: Ch&uacute;ng t&ocirc;i c&oacute; thể
          tổ hợp dữ liệu được thu thập để c&oacute; một c&aacute;i nh&igrave;n
          ho&agrave;n chỉnh hơn về một người ti&ecirc;u d&ugrave;ng v&agrave; từ
          đ&oacute; cho ph&eacute;p ch&uacute;ng t&ocirc;i phục vụ tốt hơn với
          sự c&aacute; nh&acirc;n h&oacute;a mạnh hơn ở c&aacute;c kh&iacute;a
          cạnh, bao gồm nhưng kh&ocirc;ng giới hạn: (i) để cải thiện v&agrave;
          c&aacute; nh&acirc;n h&oacute;a trải nghiệm của th&agrave;nh
          vi&ecirc;n tr&ecirc;n STMĐT Vev&eacute;. (ii) để cải thiện c&aacute;c
          tiện &iacute;ch, dịch vụ, điều chỉnh ch&uacute;ng ph&ugrave; hợp với
          c&aacute;c nhu cầu được c&aacute; thể h&oacute;a v&agrave; đi đến
          những &yacute; tưởng dịch vụ mới (iii) để phục vụ th&agrave;nh
          vi&ecirc;n với những giới thiệu, quảng c&aacute;o được điều chỉnh
          ph&ugrave; hợp với sự quan t&acirc;m của th&agrave;nh vi&ecirc;n.
        </li>
        <li>
          An Ninh: cho c&aacute;c mục đ&iacute;ch ngăn ngừa c&aacute;c hoạt động
          ph&aacute; hủy t&agrave;i khoản người d&ugrave;ng của kh&aacute;ch
          h&agrave;ng hoặc c&aacute;c hoạt động giả mạo kh&aacute;ch
          h&agrave;ng. Theo y&ecirc;u cầu của ph&aacute;pluật: t&ugrave;y quy
          định của ph&aacute;p luật v&agrave;o từng thời điểm, ch&uacute;ng
          t&ocirc;i c&oacute; thể thu thập, lưu trữ v&agrave; cung cấp theo
          y&ecirc;u cầu của cơ quan nh&agrave; nước c&oacute; thẩm quyền.
        </li>
        <li>
          Theo y&ecirc;u cầu của ph&aacute;p luật: t&ugrave;y quy định của
          ph&aacute;p luật v&agrave;o từng thời điểm, ch&uacute;ng t&ocirc;i
          c&oacute; thể thu thập, lưu trữ v&agrave; cung cấp theo y&ecirc;u cầu
          của cơ quan nh&agrave; nước c&oacute; thẩm quyền.
        </li>
      </ol>
      <ol>
        <li>
          <strong>Phạm vi thu thập v&agrave; sử dụng th&ocirc;ng tin</strong>
        </li>
      </ol>
      <p>
        3.1 Ch&uacute;ng t&ocirc;i thu thập th&ocirc;ng tin c&aacute; nh&acirc;n
        của th&agrave;nh vi&ecirc;n khi:
      </p>
      <ol>
        <li>
          Th&agrave;nh vi&ecirc;n trực tiếp cung cấp cho ch&uacute;ng t&ocirc;i.
        </li>
        <li>
          Đ&oacute; l&agrave; c&aacute;c th&ocirc;ng tin c&aacute; nh&acirc;n
          th&agrave;nh vi&ecirc;n cung cấp cho ch&uacute;ng t&ocirc;i được thực
          hiện chủ yếu tr&ecirc;n STMĐT Vev&eacute; bao gồm: họ t&ecirc;n, địa
          chỉ thư điện tử (email), số điện thoại, địa chỉ, th&ocirc;ng tin đăng
          nhập t&agrave;i khoản bao gồm th&ocirc;ng tin bất kỳ cần thiết để
          thiết lập t&agrave;i khoản v&iacute; dụ như t&ecirc;n đăng nhập, mật
          khẩu đăng nhập, ID/địa chỉ đăng nhập v&agrave; c&acirc;u hỏi/trả lời
          an ninh.
        </li>
        <li>Bạn tương t&aacute;c với ch&uacute;ng t&ocirc;i.</li>
        <li>
          Ch&uacute;ng t&ocirc;i sử dụng cookies v&agrave; c&ocirc;ng nghệ theo
          dấu kh&aacute;c để thu thập một số th&ocirc;ng tin khi bạn tương
          t&aacute;c STMĐT Vev&eacute;
        </li>
        <li>
          Từ những nguồn hợp ph&aacute;p kh&aacute;c. Ch&uacute;ng t&ocirc;i
          c&oacute; thể sẽ thu thập th&ocirc;ng tin c&aacute; nh&acirc;n từ
          c&aacute;c nguồn hợp ph&aacute;p kh&aacute;c.
        </li>
        <li>
          STMĐT Vev&eacute; sử dụng th&ocirc;ng tin th&agrave;nh vi&ecirc;n cung
          cấp để: (i) Cung cấp c&aacute;c dịch vụ đến Th&agrave;nh vi&ecirc;n;
          (ii) Gửi c&aacute;c th&ocirc;ng b&aacute;o về c&aacute;c hoạt động
          trao đổi th&ocirc;ng tin giữa th&agrave;nh vi&ecirc;n v&agrave; STMĐT
          Vev&eacute;; (iii) Ngăn ngừa c&aacute;c hoạt động ph&aacute; hủy
          t&agrave;i khoản người d&ugrave;ng của th&agrave;nh vi&ecirc;n hoặc
          c&aacute;c hoạt động giả mạo Th&agrave;nh vi&ecirc;n; (iv) Li&ecirc;n
          lạc v&agrave; giải quyết với th&agrave;nh vi&ecirc;n trong những
          trường hợp đặc biệt. (v) Kh&ocirc;ng sử dụng th&ocirc;ng tin c&aacute;
          nh&acirc;n của th&agrave;nh vi&ecirc;n ngo&agrave;i mục đ&iacute;ch
          x&aacute;c nhận v&agrave; li&ecirc;n hệ c&oacute; li&ecirc;n quan đến
          giao dịch tại Vev&eacute;.
        </li>
        <li>
          Trong trường hợp c&oacute; y&ecirc;u cầu của ph&aacute;p luật: STMĐT
          Vev&eacute; c&oacute; tr&aacute;ch nhiệm hợp t&aacute;c cung cấp
          th&ocirc;ng tin c&aacute; nh&acirc;n th&agrave;nh vi&ecirc;n khi
          c&oacute; y&ecirc;u cầu từ cơ quan tư ph&aacute;p bao gồm: Viện kiểm
          s&aacute;t, t&ograve;a &aacute;n, cơ quan c&ocirc;ng an điều tra
          li&ecirc;n quan đến h&agrave;nh vi vi phạm ph&aacute;p luật n&agrave;o
          đ&oacute; của th&agrave;nh vi&ecirc;n. Ngo&agrave;i ra, kh&ocirc;ng ai
          c&oacute; quyền x&acirc;m phạm v&agrave;o th&ocirc;ng tin c&aacute;
          nh&acirc;n của th&agrave;nh vi&ecirc;n.
        </li>
      </ol>
      <ol>
        <li>
          <strong>Thời gian lưu trữ th&ocirc;ng tin</strong>
        </li>
      </ol>
      <p>
        Dữ liệu c&aacute; nh&acirc;n của Th&agrave;nh vi&ecirc;n sẽ được lưu trữ
        cho đến khi c&oacute; y&ecirc;u cầu hủy bỏ hoặc tự th&agrave;nh
        vi&ecirc;n đăng nhập v&agrave; thực hiện hủy bỏ. C&ograve;n lại trong
        mọi trường hợp th&ocirc;ng tin c&aacute; nh&acirc;n th&agrave;nh
        vi&ecirc;n sẽ được bảo mật tr&ecirc;n m&aacute;y chủ của Vev&eacute;.
      </p>
      <ol>
        <li>
          <strong>
            Những người hoặc tổ chức c&oacute; thể được tiếp cận với th&ocirc;ng
            tin đ&oacute;
          </strong>
        </li>
      </ol>
      <p>
        Ch&uacute;ng t&ocirc;i sẽ kh&ocirc;ng cung cấp th&ocirc;ng tin c&aacute;
        nh&acirc;n của Th&agrave;nh vi&ecirc;n cho bất kỳ b&ecirc;n thứ ba
        n&agrave;o, trừ một số hoạt động cần thiết dưới đ&acirc;y:&nbsp;
      </p>
      <ol>
        <li>
          C&aacute;c đối t&aacute;c l&agrave; b&ecirc;n cung cấp dịch vụ cho
          ch&uacute;ng t&ocirc;i li&ecirc;n quan đến thực hiện đơn hang
          v&agrave; chỉ giới hạn trong phạm vi th&ocirc;ng tin cần thiết cũng
          như &aacute;p dụng c&aacute;c quy định đảm bảo an ninh, bảo mật
          c&aacute;c th&ocirc;ng tin c&aacute; nh&acirc;n.
        </li>
        <li>
          Ch&uacute;ng t&ocirc;i c&oacute; thể sử dụng dịch vụ từ một nh&agrave;
          cung cấp dịch vụ l&agrave; b&ecirc;n thứ ba để thực hiện một số hoạt
          động li&ecirc;n quan đến STMĐT Vev&eacute; v&agrave; khi đ&oacute;
          b&ecirc;n thứ ba n&agrave;y c&oacute; thể truy cập hoặc xử l&yacute;
          c&aacute;c th&ocirc;ng tin c&aacute; nh&acirc;n trong qu&aacute;
          tr&igrave;nh cung cấp c&aacute;c dịch vụ đ&oacute;. Ch&uacute;ng
          t&ocirc;i y&ecirc;u cầu c&aacute;c b&ecirc;n thứ ba n&agrave;y
          tu&acirc;n thủ mọi luật lệ về bảo vệ th&ocirc;ng tin c&aacute;
          nh&acirc;n li&ecirc;n quan v&agrave; c&aacute;c y&ecirc;u cầu về an
          ninh li&ecirc;n quan đến th&ocirc;ng tin c&aacute; nh&acirc;n.&nbsp;
        </li>
        <li>
          C&aacute;c chương tr&igrave;nh c&oacute; t&iacute;nh li&ecirc;n kết,
          đồng thực hiện, thu&ecirc; ngo&agrave;i cho c&aacute;c mục đ&iacute;ch
          được n&ecirc;u tại Mục 2 v&agrave; lu&ocirc;n &aacute;p dụng
          c&aacute;c y&ecirc;u cầu bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n.
          (iv) Y&ecirc;u cầu ph&aacute;p l&yacute;: Ch&uacute;ng t&ocirc;i
          c&oacute; thể tiết lộ c&aacute;c th&ocirc;ng tin c&aacute; nh&acirc;n
          nếu điều đ&oacute; do luật ph&aacute;p y&ecirc;u cầu v&agrave; việc
          tiết lộ như vậy l&agrave; cần thiết một c&aacute;ch hợp l&yacute; để
          tu&acirc;n thủ c&aacute;c quy tr&igrave;nh ph&aacute;p l&yacute;.
        </li>
        <li>
          Chuyển giao kinh doanh (nếu c&oacute;): trong trường hợp s&aacute;p
          nhập, hợp nhất to&agrave;n bộ hoặc một phần với c&ocirc;ng ty
          kh&aacute;c, người mua sẽ c&oacute; quyền truy cập th&ocirc;ng tin
          được ch&uacute;ng t&ocirc;i lưu trữ, duy tr&igrave; trong đ&oacute;
          bao gồm cả th&ocirc;ng tin c&aacute; nh&acirc;n.&nbsp;
        </li>
      </ol>
      <ol>
        <li>
          <strong>
            Địa chỉ của đơn vị thu thập v&agrave; quản l&yacute; th&ocirc;ng tin
            c&aacute; nh&acirc;n
          </strong>
        </li>
      </ol>
      <p>
        <strong>
          C&Ocirc;NG TY TNHH GIẢI PH&Aacute;P C&Ocirc;NG NGHỆ UBIZ
        </strong>
      </p>
      <p>T&ecirc;n người đại diện theo ph&aacute;p luật: HỒ HUY CƯỜNG</p>
      <p>
        Địa chỉ trụ sở ch&iacute;nh: Căn A12.05, Block A, Centana Thủ
        Thi&ecirc;m, 36A Mai Ch&iacute; Thọ, Phường An Ph&uacute;, Th&agrave;nh
        phố Thủ Đức, Th&agrave;nh phố Hồ Ch&iacute; Minh, Việt Nam&nbsp;
      </p>
      <p>
        Email: <strong>hi@veve.com.vn</strong>
      </p>
      <ol>
        <li>
          <strong>
            Phương tiện v&agrave; c&ocirc;ng cụ để người d&ugrave;ng tiếp cận
            v&agrave; chỉnh sửa dữ liệu c&aacute; nh&acirc;n của m&igrave;nh.
          </strong>
        </li>
      </ol>
      <ol>
        <li>
          Th&agrave;nh vi&ecirc;n c&oacute; quyền tự kiểm tra, cập nhật, điều
          chỉnh hoặc hủy bỏ th&ocirc;ng tin c&aacute; nh&acirc;n của m&igrave;nh
          bằng c&aacute;ch đăng nhập v&agrave;o t&agrave;i khoản v&agrave; chỉnh
          sửa th&ocirc;ng tin c&aacute; nh&acirc;n hoặc y&ecirc;u cầu
          Vev&eacute; thực hiện việc n&agrave;y.
        </li>
        <li>
          Th&agrave;nh vi&ecirc;n c&oacute; quyền gửi y&ecirc;u cầu hoặc nhờ ban
          quản trị STMĐT Vev&eacute; hỗ trợ v&agrave; cung cấp lại mật khẩu hoặc
          t&agrave;i khoản do qu&ecirc;n mật khẩu hoặc bị kh&oacute;a do đăng
          nhập sai nhiều lần bằng c&aacute;ch gọi về Hotline: ---- hoặc gửi
          th&ocirc;ng tin cần hỗ trợ qua email của ban quản trị Email:
          hi@veve.com.vn.
        </li>
        <li>
          Th&agrave;nh vi&ecirc;n c&oacute; quyền gửi khiếu nại về việc lộ
          th&ocirc;ng tin c&aacute;c nh&acirc;n cho b&ecirc;n thứ 3 đến Ban quản
          trị của STMĐT Vev&eacute;. Khi tiếp nhận những phản hồi n&agrave;y,
          Vev&eacute; sẽ x&aacute;c nhận lại th&ocirc;ng tin, phải c&oacute;
          tr&aacute;ch nhiệm trả lời l&yacute; do v&agrave; hướng dẫn
          th&agrave;nh vi&ecirc;n kh&ocirc;i phục v&agrave; bảo mật lại
          th&ocirc;ng tin.
        </li>
      </ol>
      <ol>
        <li>
          <strong>
            Cam kết bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n kh&aacute;ch
            h&agrave;ng
          </strong>
        </li>
      </ol>
      <ol>
        <li>
          Th&ocirc;ng tin c&aacute; nh&acirc;n của th&agrave;nh vi&ecirc;n
          tr&ecirc;n Vev&eacute; được Vev&eacute; cam kết bảo mật tuyệt đối theo
          ch&iacute;nh s&aacute;ch bảo vệ th&ocirc;ng tin c&aacute; nh&acirc;n
          của Vev&eacute;. Việc thu thập v&agrave; sử dụng th&ocirc;ng tin của
          mỗi th&agrave;nh vi&ecirc;n chỉ được thực hiện khi c&oacute; sự đồng
          &yacute; của kh&aacute;ch h&agrave;ng đ&oacute; trừ những trường hợp
          ph&aacute;p luật c&oacute; quy định kh&aacute;c.
        </li>
        <li>
          Kh&ocirc;ng sử dụng, kh&ocirc;ng chuyển giao, cung cấp hay tiết lộ cho
          b&ecirc;n thứ 3 n&agrave;o về th&ocirc;ng tin c&aacute; nh&acirc;n của
          th&agrave;nh vi&ecirc;n khi kh&ocirc;ng c&oacute; sự cho ph&eacute;p
          đồng &yacute; từ th&agrave;nh vi&ecirc;n.
        </li>
        <li>
          Trong trường hợp m&aacute;y chủ lưu trữ th&ocirc;ng tin bị hacker tấn
          c&ocirc;ng dẫn đến mất m&aacute;t dữ liệu c&aacute; nh&acirc;n
          th&agrave;nh vi&ecirc;n, Vev&eacute; sẽ c&oacute; tr&aacute;ch nhiệm
          th&ocirc;ng b&aacute;o vụ việc cho cơ quan chức năng điều tra xử
          l&yacute; kịp thời v&agrave; th&ocirc;ng b&aacute;o cho th&agrave;nh
          vi&ecirc;n được biết.
        </li>
        <li>
          Bảo mật tuyệt đối mọi th&ocirc;ng tin giao dịch trực tuyến của
          Th&agrave;nh vi&ecirc;n bao gồm th&ocirc;ng tin h&oacute;a đơn kế
          to&aacute;n chứng từ số h&oacute;a tại khu vực dữ liệu trung t&acirc;m
          an to&agrave;n cấp 1 của Vev&eacute;.
        </li>
        <li>
          Ban quản l&yacute; Vev&eacute; y&ecirc;u cầu c&aacute;c c&aacute;
          nh&acirc;n khi đăng k&yacute; l&agrave; th&agrave;nh vi&ecirc;n, phải
          cung cấp đầy đủ th&ocirc;ng tin c&aacute; nh&acirc;n c&oacute;
          li&ecirc;n quan như: Họ v&agrave; t&ecirc;n, địa chỉ li&ecirc;n lạc,
          email, số chứng minh nh&acirc;n d&acirc;n, điện thoại, số t&agrave;i
          khoản, số thẻ thanh to&aacute;n,v.v&hellip; v&agrave; chịu
          tr&aacute;ch nhiệm về t&iacute;nh ph&aacute;p l&yacute; của những
          th&ocirc;ng tin tr&ecirc;n. Ban quản l&yacute; Vev&eacute; kh&ocirc;ng
          chịu tr&aacute;ch nhiệm cũng như kh&ocirc;ng giải quyết mọi khiếu nại
          c&oacute; li&ecirc;n quan đến quyền lợi của Th&agrave;nh vi&ecirc;n
          đ&oacute; nếu x&eacute;t thấy tất cả th&ocirc;ng tin c&aacute;
          nh&acirc;n của th&agrave;nh vi&ecirc;n đ&oacute; cung cấp khi đăng
          k&yacute; ban đầu l&agrave; kh&ocirc;ng ch&iacute;nh x&aacute;c.
        </li>
      </ol>
      <ol>
        <li>
          <strong>Hiệu lực</strong>
        </li>
      </ol>
      <p>
        Ch&iacute;nh S&aacute;ch Bảo Mật Th&ocirc;ng Tin C&aacute; Nh&acirc;n
        c&oacute; hiệu lực từ ng&agrave;y 20/11/2023
      </p>
    </Policy>
  );
};
