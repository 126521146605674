/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiCompass = ({ className }) => {
  return (
    <svg
      className={`li-compass ${className}`}
      fill="none"
      height="11"
      viewBox="0 0 11 11"
      width="11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_448_1564)">
        <path
          className="path"
          clipRule="evenodd"
          d="M4.68949 2.76786C3.0485 3.19307 2.06292 4.86806 2.48813 6.50904C2.91334 8.15003 4.58833 9.13561 6.22932 8.7104C7.8703 8.28518 8.85588 6.6102 8.43067 4.96921C8.00546 3.32822 6.33047 2.34264 4.68949 2.76786ZM1.82785 6.68014C1.30814 4.67448 2.51274 2.62728 4.51839 2.10758C6.52404 1.58787 8.57125 2.79247 9.09095 4.79812C9.61066 6.80377 8.40606 8.85097 6.40041 9.37068C4.39476 9.89038 2.34755 8.68579 1.82785 6.68014Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M6.6695 3.68266C6.78083 3.74817 6.8456 3.87096 6.8368 3.99984L6.68097 6.2809C6.67365 6.38799 6.61634 6.48539 6.52628 6.5438L4.60794 7.78777C4.49955 7.85806 4.36076 7.86107 4.24943 7.79556C4.13809 7.73005 4.07333 7.60726 4.08213 7.47838L4.23795 5.19733C4.24527 5.09023 4.30258 4.99283 4.39265 4.93442L6.31099 3.69045C6.41937 3.62017 6.55816 3.61715 6.6695 3.68266ZM4.90684 5.41394L4.80912 6.84437L6.01209 6.06428L6.10981 4.63386L4.90684 5.41394Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_448_1564">
          <rect
            className="rect"
            fill="white"
            height="8.18507"
            transform="translate(0.471191 2.80399) rotate(-14.527)"
            width="8.18507"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
