/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconMusic = ({ className }) => {
  return (
    <svg
      className={`icon-music ${className}`}
      fill="none"
      height="15"
      viewBox="0 0 15 15"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_429_298)">
        <path
          className="path"
          d="M6.4349 10.4836L4.87675 4.4704L10.1877 2.107L11.7459 8.12022"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.07512"
        />
        <path
          className="path"
          d="M5.4068 12.2309C6.17319 12.0323 6.63348 11.25 6.4349 10.4836C6.23631 9.71723 5.45404 9.25693 4.68766 9.45552C3.92127 9.65411 3.46097 10.4364 3.65956 11.2028C3.85815 11.9691 4.64041 12.4294 5.4068 12.2309Z"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.07512"
        />
        <path
          className="path"
          d="M10.7178 9.86745C11.4841 9.66887 11.9444 8.8866 11.7459 8.12021C11.5473 7.35383 10.765 6.89353 9.99861 7.09212C9.23223 7.2907 8.77193 8.07297 8.97052 8.83936C9.16911 9.60575 9.95137 10.066 10.7178 9.86745Z"
          stroke="#334155"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.07512"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_429_298">
          <rect
            className="rect"
            fill="white"
            height="11.468"
            transform="translate(0.114456 3.23633) rotate(-14.527)"
            width="11.468"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
