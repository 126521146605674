/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiMapPin = ({ className }) => {
  return (
    <svg
      className={`li-map-pin ${className}`}
      fill="none"
      height="6"
      viewBox="0 0 6 6"
      width="6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_448_1956)">
        <path
          className="path"
          clipRule="evenodd"
          d="M3.09307 0.881345C2.67692 0.881345 2.27782 1.04666 1.98356 1.34092C1.6893 1.63518 1.52398 2.03428 1.52398 2.45043C1.52398 3.05807 1.91934 3.64769 2.35284 4.10527C2.56558 4.32983 2.77899 4.51367 2.93943 4.64142C2.99914 4.68897 3.05129 4.72858 3.09307 4.75945C3.13484 4.72858 3.18699 4.68897 3.2467 4.64142C3.40714 4.51367 3.62055 4.32983 3.83329 4.10527C4.26679 3.64769 4.66215 3.05807 4.66215 2.45043C4.66215 2.03428 4.49683 1.63518 4.20257 1.34092C3.90832 1.04666 3.50921 0.881345 3.09307 0.881345ZM3.09307 5.00019C2.98427 5.16338 2.98422 5.16335 2.98416 5.16331L2.9836 5.16293L2.9823 5.16206L2.9778 5.15902C2.97398 5.15642 2.96854 5.1527 2.96157 5.14787C2.94764 5.13823 2.92762 5.12417 2.90238 5.10594C2.85193 5.0695 2.7805 5.01631 2.69507 4.94829C2.52454 4.81249 2.29664 4.61631 2.06807 4.37505C1.61897 3.90099 1.13171 3.21574 1.13171 2.45043C1.13171 1.93024 1.33836 1.43137 1.70618 1.06354C2.07401 0.695717 2.57288 0.489075 3.09307 0.489075C3.61325 0.489075 4.11213 0.695717 4.47995 1.06354C4.84778 1.43137 5.05442 1.93024 5.05442 2.45043C5.05442 3.21574 4.56717 3.90099 4.11806 4.37505C3.8895 4.61631 3.6616 4.81249 3.49106 4.94829C3.40564 5.01631 3.33421 5.0695 3.28375 5.10594C3.25851 5.12417 3.2385 5.13823 3.22456 5.14787C3.2176 5.1527 3.21215 5.15642 3.20833 5.15902L3.20384 5.16206L3.20253 5.16293L3.20212 5.16321C3.20206 5.16325 3.20186 5.16338 3.09307 5.00019ZM3.09307 5.00019L3.20186 5.16338C3.13598 5.2073 3.05005 5.20723 2.98416 5.16331L3.09307 5.00019Z"
          fill="#3A3555"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M3.09301 2.05816C2.87637 2.05816 2.70074 2.23378 2.70074 2.45043C2.70074 2.66707 2.87637 2.8427 3.09301 2.8427C3.30966 2.8427 3.48528 2.66707 3.48528 2.45043C3.48528 2.23378 3.30966 2.05816 3.09301 2.05816ZM2.30847 2.45043C2.30847 2.01714 2.65972 1.66589 3.09301 1.66589C3.5263 1.66589 3.87755 2.01714 3.87755 2.45043C3.87755 2.88372 3.5263 3.23497 3.09301 3.23497C2.65972 3.23497 2.30847 2.88372 2.30847 2.45043Z"
          fill="#3A3555"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_448_1956">
          <rect
            className="rect"
            fill="white"
            height="4.70725"
            transform="translate(0.73938 0.489075)"
            width="4.70725"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
