import { useState, useEffect, useLayoutEffect } from "react";
import { Desktop } from "../components/desktop/screens/Desktop";
import { MobileView } from "../components/mobile/screens/Frame/MobileView";

export const HomePage = () => {
  const [element, setElement] = useState(<Desktop />);
  const innerWidth = window.innerWidth;

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 768) {
        setElement(<Desktop />);
      } else {
        setElement(<MobileView />);
      }
    }

    window.addEventListener("resize", handleResize);
  }, []);

  useLayoutEffect(() => {
    if (innerWidth > 768) {
      setElement(<Desktop />);
    } else {
      setElement(<MobileView />);
    }
  }, [innerWidth]);

  return element;
};
