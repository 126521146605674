/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const LiChevronLeft2 = ({ color = "black", className }) => {
  return (
    <svg
      className={`li-chevron-left-2 ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M11.4466 3.99474C11.7234 4.27158 11.7234 4.72042 11.4466 4.99726L7.69453 8.74933L11.4466 12.5014C11.7234 12.7782 11.7234 13.2271 11.4466 13.5039C11.1698 13.7808 10.7209 13.7808 10.4441 13.5039L6.19075 9.25059C5.91391 8.97375 5.91391 8.52491 6.19075 8.24807L10.4441 3.99474C10.7209 3.7179 11.1698 3.7179 11.4466 3.99474Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

LiChevronLeft2.propTypes = {
  color: PropTypes.string,
};
