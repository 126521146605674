import { Policy } from "../components/policy/screens/Frame/Policy";

export const DisputeSettlementPolicy = () => {
  return (
    <Policy title={"Giải Quyết Tranh Chấp"}>
      <p style={{ textAlign: "center" }}>
        <strong>
          CƠ CHẾ GIẢI QUYẾT TRANH CHẤP PH&Aacute;T SINH TR&Ecirc;N S&Agrave;N
          GIAO DỊCH THƯƠNG MẠI ĐIỆN TỬ VEV&Eacute;
        </strong>
      </p>
      <p>
        Vev&eacute; c&oacute; tr&aacute;ch nhiệm tiếp nhận tranh chấp, khiếu nại
        v&agrave; hỗ trợ Người tham gia sự kiện giải quyết c&aacute;c khiếu nại
        về chất lượng h&agrave;ng h&oacute;a, dịch vụ được khuyến mại hoặc
        h&agrave;ng h&oacute;a dịch vụ d&ugrave;ng để khuyến mại tại STMĐT
        Vev&eacute;.
      </p>
      <p>
        Khi ph&aacute;t sinh tranh chấp, STMĐT Vev&eacute; đề cao giải
        ph&aacute;p thương lượng, h&ograve;a giải giữa c&aacute;c b&ecirc;n nhằm
        duy tr&igrave; sự tin cậy của kh&aacute;ch h&agrave;ng v&agrave;o chất
        lượng dịch vụ của Nh&agrave; tổ chức sự kiện được đăng b&aacute;n
        tr&ecirc;n STMĐT Vev&eacute; v&agrave; thực hiện theo c&aacute;c bước
        sau:
      </p>
      <p>
        <strong>Bước 1:</strong>
      </p>
      <p>
        Th&agrave;nh vi&ecirc;n Người tham gia sự kiện c&oacute; quyền khiếu nại
        qua email: hi@veve.com.vn
      </p>
      <p>
        <strong>Bước 2:</strong>
      </p>
      <p>
        Bộ phận Chăm S&oacute;c Kh&aacute;ch H&agrave;ng của Vev&eacute; sẽ tiếp
        nhận c&aacute;c khiếu nại củath&agrave;nh vi&ecirc;n Người tham gia sự
        kiện căn cứ theo t&iacute;nh chất v&agrave; mức độ của khiếu nại
        th&igrave; b&ecirc;n Vev&eacute; sẽ c&oacute; những biện ph&aacute;p cụ
        thể hỗ trợ để giải quyết tranh chấp đ&oacute;.Tr&ecirc;n cơ sở khiếu nại
        của Người tham gia sự kiện đối vơi Nh&agrave; tổ chức sự kiện,
        Nh&agrave; tổ chức sự kiện cần c&oacute; tr&aacute;ch nhiệm cung cấp văn
        bản giấy tờ chứng thực th&ocirc;ng tin li&ecirc;n quan đến sự việc đang
        g&acirc;y m&acirc;u thuẫn cho kh&aacute;ch h&agrave;ng. Nếu c&oacute; sở
        cở chứng minh lỗi thuộc về Nh&agrave; tổ chức sự kiện: Vev&eacute; sẽ
        c&oacute; biện ph&aacute;p cảnh c&aacute;o, kh&oacute;a t&agrave;i khoản
        hoặc chuyển cho cơ quan ph&aacute;p luật c&oacute; thẩm quyền t&ugrave;y
        theo mức độ của sai phạm. Vev&eacute; sẽ chấm dứt v&agrave; gỡ bỏ
        to&agrave;n bộ tin b&agrave;i về sản phẩm, dịch vụ của Nh&agrave; tổ
        chức sự kiện đ&oacute; tr&ecirc;n Vev&eacute; đồng thời y&ecirc;u cầu
        Nh&agrave; tổ chức sự kiện bồi ho&agrave;n cho kh&aacute;ch h&agrave;ng
        thỏa đ&aacute;ng tr&ecirc;n cơ sở thỏa thuận với kh&aacute;ch
        h&agrave;ng.
      </p>
      <p>
        <strong>Bước 3:</strong>
      </p>
      <p>
        Nếu th&ocirc;ng qua h&igrave;nh thức thỏa thuận m&agrave; vẫn
        kh&ocirc;ng thể giải quyết được m&acirc;u thuẫn ph&aacute;t sinh từ giao
        dịch giữa 2 b&ecirc;n Người tham gia sự kiện, Nh&agrave; tổ chức sự
        kiện, th&igrave; một trong 2 b&ecirc;n Người tham gia sự kiện v&agrave;
        Nh&agrave; tổ chức sự kiện sẽ c&oacute; quyền nhờ đến cơ quan
        ph&aacute;p luật c&oacute; thẩm quyền can thiệp nhằm đảm bảo lợi
        &iacute;ch hợp ph&aacute;p của c&aacute;c b&ecirc;n nhất l&agrave; cho
        kh&aacute;ch h&agrave;ng.
      </p>
      <p>Người tham gia sự kiện gửi khiếu nại tại địa chỉ:</p>
      <p>
        <strong>
          C&Ocirc;NG TY TNHH GIẢI PH&Aacute;P C&Ocirc;NG NGHỆ UBIZ
        </strong>
      </p>
      <p>T&ecirc;n người đại diện theo ph&aacute;p luật: HỒ HUY CƯỜNG</p>
      <p>
        Địa chỉ trụ sở ch&iacute;nh: Căn A12.05, Block A, Centana Thủ
        Thi&ecirc;m, 36A Mai Ch&iacute; Thọ, Phường An Ph&uacute;, Th&agrave;nh
        phố Thủ Đức, Th&agrave;nh phố Hồ Ch&iacute; Minh, Việt Nam&nbsp;
      </p>
      <p>
        Email: <strong>hi@veve.com.vn</strong>
      </p>
      <p>
        <strong>Thời gian giải quyết tranh chấp: </strong>7 ng&agrave;y
        l&agrave;m việc, trong trường hợp vụ việc phức tạp th&igrave; thời gian
        giải quyết tranh chấp khiếu nại c&oacute; thể d&agrave;i hơn v&agrave;
        sẽ được th&ocirc;ng b&aacute;o cụ thể cho người khiếu nại v&agrave;
        người bị khiếu nại thời gian giải quyết cụ thể được biết.
      </p>
    </Policy>
  );
};
