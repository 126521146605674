import React from "react";
import { IconChevronsUpDown } from "../../icons/IconChevronsUpDown";
import { IconMusic } from "../../icons/IconMusic";
import { IconQrCode } from "../../icons/IconQrCode";
import { LiBell } from "../../icons/LiBell";
import { LiBell1 } from "../../icons/LiBell1";
import { LiCalendar } from "../../icons/LiCalendar";
import { LiCalendar2 } from "../../icons/LiCalendar2";
import { LiChevronLeft2 } from "../../icons/LiChevronLeft2";
import { LiClock } from "../../icons/LiClock";
import { LiCompass } from "../../icons/LiCompass";
import { LiCompass1 } from "../../icons/LiCompass1";
import { LiMapPin } from "../../icons/LiMapPin";
import { LiMapPin2 } from "../../icons/LiMapPin2";
import { LiQrCodeReady } from "../../icons/LiQrCodeReady";
import { LiSend1 } from "../../icons/LiSend1";
import { LiSend2 } from "../../icons/LiSend2";
import { LiTicket } from "../../icons/LiTicket";
import { LiTicket1 } from "../../icons/LiTicket1";
import { LiUser } from "../../icons/LiUser";
import { LiUser1 } from "../../icons/LiUser1";
import "./desktop-style.css";
import { RoutePaths } from "../../../../App";
import { Outlet, Link } from "react-router-dom";
import { appStoreUrl, chPlayUrl } from "../../../../common/constans";

export const Desktop = () => {
  return (
    <div className="desktop">
      <div className="div">
        <div className="overlap">
          <div className="rectangle" />
          <div className="frame">
            <div className="overlap-group">
              <div className="rectangle-2" />
              <div className="ellipse" />
              <p className="vev-ngay-th-i-ch-chi">
                Vevé ngay thôi!
                <br />
                Chờ chi nữa
              </p>
              <div className="iphone-pro">
                <div className="overlap-2">
                  <div className="div-2">
                    <div className="overlap-group-2">
                      <div className="screen-background" />
                      <div className="stroke-body-outside" />
                      <div className="stroke-body" />
                      <img
                        className="screen"
                        alt="Screen"
                        src="/img/screen.png"
                      />
                    </div>
                  </div>
                  <div className="div-2">
                    <div className="bottom" />
                    <div className="left-bottom" />
                    <div className="left-top" />
                    <div className="right-bottom" />
                    <div className="right" />
                    <div className="top" />
                  </div>
                  <div className="buttons">
                    <div className="button" />
                    <div className="button-2" />
                    <div className="silence-button" />
                    <div className="power-button" />
                  </div>
                  <div className="front-camera">
                    <div className="overlap-3">
                      <div className="lens" />
                      <img className="line" alt="Line" src="/img/line.svg" />
                    </div>
                  </div>
                  <div className="notch" />
                  <div className="text-wrapper">vevé</div>
                </div>
              </div>
              <div className="pixel">
                <div className="overlap-4">
                  <img
                    className="right-buttons"
                    alt="Right buttons"
                    src="/img/right-buttons.png"
                  />
                  <div className="phone-shell">
                    <div className="overlap-group-3">
                      <div className="outer-shine" />
                      <img
                        className="left-shine-stroke"
                        alt="Left shine stroke"
                        src="/img/left-shine-stroke.svg"
                      />
                      <img
                        className="right-shine-stroke"
                        alt="Right shine stroke"
                        src="/img/right-shine-stroke.svg"
                      />
                    </div>
                  </div>
                  <div className="insert-content-here-wrapper">
                    <div className="insert-content-here">
                      <img
                        className="img"
                        alt="Frame"
                        src="/img/frame-16.png"
                      />
                      <div className="text-wrapper-2">vevé</div>
                    </div>
                  </div>
                  <div className="camera">
                    <div className="overlap-5">
                      <div className="lens-black" />
                      <img
                        className="lens-shine"
                        alt="Lens shine"
                        src="/img/lens-shine.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <img
                className="appstore"
                alt="Appstore"
                src="/img/appstore.png"
                onClick={() => {
                  window.open(appStoreUrl, "_blank");
                }}
              />
              <img
                className="googleplay"
                alt="Googleplay"
                src="/img/googleplay.png"
                onClick={() => {
                  window.open(chPlayUrl, "_blank");
                }}
              />
            </div>
          </div>
          <p className="s-n-ph-m-c-a-c-ng-ty">
            Sản phẩm của
            <br />
            Công ty TNHH Giải pháp Công nghệ Ubiz
          </p>
          <div className="text-wrapper-3">Email: hi@veve.com.vn</div>

          <div className="text-wrapper-4">
            <Link to={RoutePaths.termsOfService}>Điều Khoản Sử Dụng</Link>
          </div>
          <div className="text-wrapper-4">
            <Link to={RoutePaths.privacyPolicy}>Chính Sách Bảo Mật</Link>
          </div>
          <div className="text-wrapper-4">
            <Link to={RoutePaths.refundPolicy}>Chính Sách Hoàn Trả</Link>
          </div>
          <div className="text-wrapper-4">
            <Link to={RoutePaths.disputeSettlementPolicy}>
              Giải Quyết Tranh Chấp
            </Link>
          </div>
          <img className="vector" alt="Vector" src="/img/vector.svg" />
        </div>
        <div className="overlap-6">
          <div className="overlap-7">
            <div className="overlap-8">
              <div className="ellipse-2" />
              <div className="ellipse-3" />
              <div className="ellipse-4" />
              <div className="iphone-mini">
                <div className="frame-2">
                  <div className="frame-3">
                    <LiCompass1 className="icon-instance-node" />
                    <div className="text-wrapper-5">Khám phá</div>
                  </div>
                  <div className="frame-4">
                    <LiTicket1 className="icon-instance-node" />
                    <div className="text-wrapper-6">Vé của tôi</div>
                  </div>
                  <div className="frame-4">
                    <LiBell1 className="icon-instance-node" />
                    <div className="text-wrapper-5">Thông báo</div>
                  </div>
                  <div className="frame-4">
                    <LiUser1 className="icon-instance-node" />
                    <div className="text-wrapper-5">Tôi</div>
                  </div>
                </div>
                <div className="text-wrapper-7">Vé của tôi</div>
                <div className="text-wrapper-8">Chủ Nhật, 20/08/2023</div>
                <div className="text-wrapper-9">Chủ Nhật, 27/08/2023</div>
                <div className="frame-5">
                  <img
                    className="rectangle-3"
                    alt="Rectangle"
                    src="/img/rectangle-34-6.png"
                  />
                  <div className="frame-6">
                    <div className="text-wrapper-10">Acoustic Night: Đến</div>
                    <div className="frame-7">
                      <div className="frame-8">
                        <LiCalendar2 className="icon-instance-node-2" />
                      </div>
                      <p className="text-wrapper-11">
                        20:00 - 23:00 Chủ Nhật, 20/08/2023
                      </p>
                    </div>
                    <div className="frame-7">
                      <div className="frame-8">
                        <LiMapPin2 className="icon-instance-node-2" />
                      </div>
                      <p className="text-wrapper-12">
                        Cửa hàng 81 Cafe - 30 Nguyễn Hữu Cầu, P. Tân Định, Quận
                        1, TP. Hồ Chí Minh
                      </p>
                    </div>
                  </div>
                  <div className="div-wrapper">
                    <div className="text-wrapper-13">3 vé</div>
                  </div>
                  <div className="frame-9">
                    <LiQrCodeReady className="icon-instance-node-3" />
                    <div className="text-wrapper-14">QR sẵn sàng</div>
                  </div>
                </div>
                <div className="frame-10">
                  <img
                    className="rectangle-3"
                    alt="Rectangle"
                    src="/img/rectangle-34-5.png"
                  />
                  <div className="frame-6">
                    <div className="text-wrapper-10">Acoustic Night: Về</div>
                    <div className="frame-7">
                      <div className="frame-8">
                        <LiCalendar2 className="icon-instance-node-2" />
                      </div>
                      <p className="text-wrapper-11">
                        20:00 - 23:00 Chủ Nhật, 20/08/2023
                      </p>
                    </div>
                    <div className="frame-7">
                      <div className="frame-8">
                        <LiMapPin2 className="icon-instance-node-2" />
                      </div>
                      <p className="text-wrapper-12">
                        Cửa hàng 81 Cafe - 30 Nguyễn Hữu Cầu, P. Tân Định, Quận
                        1, TP. Hồ Chí Minh
                      </p>
                    </div>
                  </div>
                  <div className="frame-11">
                    <div className="text-wrapper-13">3 vé</div>
                  </div>
                  <div className="frame-12">
                    <LiSend2 className="icon-instance-node-3" />
                    <div className="text-wrapper-14">Chuyển nhượng</div>
                  </div>
                </div>
                <div className="bars-status-default">
                  <div className="action">
                    <div className="time">9:41</div>
                  </div>
                  <div className="container">
                    <div className="battery">
                      <div className="rectangle-wrapper">
                        <div className="rectangle-4" />
                      </div>
                    </div>
                    <img
                      className="combined-shape"
                      alt="Combined shape"
                      src="/img/combined-shape-4.svg"
                    />
                    <img className="wi-fi" alt="Wi fi" src="/img/wi-fi-4.svg" />
                  </div>
                </div>
                <LiClock className="li-clock" />
              </div>
              <div className="iphone-mini-2">
                <p className="text-wrapper-15">Tp. Hồ Chí Minh - Quận 3</p>
                <div className="frame-13">
                  <div className="text-wrapper-16">Acoustic</div>
                  <div className="frame-14">
                    <div className="frame-15">
                      <IconMusic className="icon-music" />
                      <div className="text-wrapper-17">Âm nhạc</div>
                    </div>
                    <IconChevronsUpDown className="icon-chevrons-up" />
                  </div>
                </div>
                <div className="frame-16">
                  <img
                    className="rectangle-5"
                    alt="Rectangle"
                    src="/img/rectangle-34-4.png"
                  />
                  <div className="frame-17">
                    <div className="text-wrapper-18">Acoustic Night: Đến</div>
                    <div className="frame-18">
                      <div className="text-wrapper-19">
                        Chủ Nhật, 20/08/2023
                      </div>
                      <div className="text-wrapper-20">Cửa hàng 81 Cafe</div>
                    </div>
                  </div>
                  <div className="frame-19">
                    <div className="text-wrapper-21">từ 30.000 đ</div>
                  </div>
                </div>
                <div className="overlap-9">
                  <div className="frame-20">
                    <img
                      className="rectangle-5"
                      alt="Rectangle"
                      src="/img/rectangle-34-3.png"
                    />
                    <div className="frame-17">
                      <div className="text-wrapper-18">Acoustic Night: Về</div>
                      <div className="frame-18">
                        <div className="text-wrapper-19">
                          Chủ Nhật, 27/08/2023
                        </div>
                        <div className="text-wrapper-20">Cửa hàng 81 Cafe</div>
                      </div>
                    </div>
                    <div className="frame-19">
                      <div className="text-wrapper-21">từ 30.000 đ</div>
                    </div>
                  </div>
                  <div className="frame-21">
                    <div className="frame-22">
                      <LiCompass className="icon-instance-node-4" />
                      <div className="text-wrapper-22">Khám phá</div>
                    </div>
                    <div className="frame-23">
                      <LiTicket className="icon-instance-node-4" />
                      <div className="text-wrapper-23">Vé của tôi</div>
                    </div>
                    <div className="frame-23">
                      <LiBell className="icon-instance-node-4" />
                      <div className="text-wrapper-23">Thông báo</div>
                    </div>
                    <div className="frame-23">
                      <LiUser className="icon-instance-node-4" />
                      <div className="text-wrapper-23">Tôi</div>
                    </div>
                  </div>
                </div>
                <img
                  className="vector-2"
                  alt="Vector"
                  src="/img/vector-1-1.svg"
                />
                <div className="text-wrapper-24">2 kết quả</div>
                <div className="overlap-10">
                  <img
                    className="frame-24"
                    alt="Frame"
                    src="/img/frame-16-1.svg"
                  />
                  <div className="bars-status-default-2">
                    <div className="time-wrapper">
                      <div className="time">9:41</div>
                    </div>
                    <div className="container-2">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-4">
                          <div className="rectangle-6" />
                        </div>
                      </div>
                      <img
                        className="combined-shape-2"
                        alt="Combined shape"
                        src="/img/combined-shape-2.svg"
                      />
                      <img
                        className="wi-fi-2"
                        alt="Wi fi"
                        src="/img/wi-fi-2.svg"
                      />
                    </div>
                  </div>
                  <div className="bars-status-default-2">
                    <div className="time-wrapper">
                      <div className="time">9:41</div>
                    </div>
                    <div className="container-2">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-4">
                          <div className="rectangle-6" />
                        </div>
                      </div>
                      <img
                        className="combined-shape-2"
                        alt="Combined shape"
                        src="/img/combined-shape-2.svg"
                      />
                      <img
                        className="wi-fi-2"
                        alt="Wi fi"
                        src="/img/wi-fi-2.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <img className="vector-3" alt="Vector" src="/img/vector-1.svg" />
            </div>
            <div className="text-wrapper-25">Vé sự kiện?</div>
            <img
              className="googleplay-2"
              alt="Googleplay"
              src="/img/googleplay.png"
              onClick={() => {
                window.open(chPlayUrl, "_blank");
              }}
            />
          </div>
          <div className="text-wrapper-26">Đơn giản là</div>
          <img className="frame-25" alt="Frame" src="/img/frame-39.svg" />
          <img
            className="appstore-2"
            alt="Appstore"
            src="/img/appstore-1.png"
            onClick={() => {
              window.open(appStoreUrl, "_blank");
            }}
          />
        </div>
        <div className="frame-26">
          <div className="text-wrapper-27">Mua vé?</div>
          <div className="text-wrapper-28">Đơn giản</div>
          <p className="text-wrapper-29">
            Chỉ cần chưa đầy 5 phút, bạn đã có thể hoàn thành quá trình mua vé
            của mình với ví Momo.
          </p>
        </div>
        <div className="frame-27">
          <div className="text-wrapper-27">Săn sự kiện?</div>
          <div className="text-wrapper-30">Siêu nhàn</div>
          <p className="text-wrapper-29">
            Nhiều và rất nhiều sự kiện sẽ đổ bộ Vevé, thoả thích săn mãi không
            hết.
          </p>
        </div>
        <div className="frame-28">
          <div className="text-wrapper-27">Bán vé?</div>
          <p className="text-wrapper-29">
            Bạn là nhà tổ chức nghiệp dư? Bạn chưa quen với việc sử dụng các nền
            tảng bán vé? Bạn còn bán vé thủ công qua Forms? Chúng tôi ở đây để
            giúp bạn
          </p>
          <div className="text-wrapper-31">Dễ dàng</div>
        </div>
        <div className="overlap-wrapper">
          <div className="overlap-11">
            <div className="ellipse-5" />
            <div className="iphone-mini-3">
              <img
                className="rectangle-7"
                alt="Rectangle"
                src="/img/rectangle-34-1.png"
              />
              <div className="overlap-group-5">
                <div className="frame-29">
                  <div className="frame-30">
                    <img
                      className="image"
                      alt="Image"
                      src="/img/image-4-1.png"
                    />
                    <div className="text-wrapper-32">Thanh toán với Momo</div>
                  </div>
                  <div className="text-wrapper-33">Tổng</div>
                </div>
                <div className="text-wrapper-34">160.000đ</div>
              </div>
              <div className="text-wrapper-35">Thanh toán</div>
              <div className="frame-31">
                <div className="frame-32">
                  <div className="frame-33">
                    <div className="text-wrapper-36">Vé tham dự</div>
                    <div className="text-wrapper-37">2</div>
                    <div className="text-wrapper-38">30.000 đ</div>
                  </div>
                  <div className="frame-33">
                    <div className="text-wrapper-36">Vé hạng nhất</div>
                    <div className="text-wrapper-37">1</div>
                    <div className="text-wrapper-38">100.000đ</div>
                  </div>
                </div>
                <div className="text-wrapper-39">Hạng vé</div>
                <div className="text-wrapper-40">Số lượng</div>
                <div className="text-wrapper-41">Giá</div>
              </div>
              <div className="frame-34">
                <div className="text-wrapper-42">Acoustic Night: Đến</div>
                <div className="frame-35">
                  <div className="frame-36">
                    <LiCalendar className="icon-instance-node-5" />
                  </div>
                  <p className="text-wrapper-43">
                    20:00 - 23:00 Chủ Nhật, 20/08/2023
                  </p>
                </div>
                <div className="frame-35">
                  <div className="frame-36">
                    <LiMapPin className="icon-instance-node-5" />
                  </div>
                  <p className="text-wrapper-44">
                    Cửa hàng 81 Cafe - 30 Nguyễn Hữu Cầu, P. Tân Định, Quận 1,
                    TP. Hồ Chí Minh
                  </p>
                </div>
              </div>
              <div className="text-wrapper-45">Đơn hàng</div>
              <LiChevronLeft2 className="li-chevron-left" color="#3A3555" />
              <div className="text-wrapper-46">Quay lại</div>
              <div className="bars-status-default-3">
                <div className="action-2">
                  <div className="time">9:41</div>
                </div>
                <div className="container-3">
                  <div className="battery-2">
                    <div className="rectangle-8" />
                  </div>
                  <img
                    className="combined-shape-3"
                    alt="Combined shape"
                    src="/img/combined-shape-1.svg"
                  />
                  <img className="wi-fi-3" alt="Wi fi" src="/img/wi-fi-1.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-37">
          <div className="overlap-11">
            <div className="ellipse-6" />
            <div className="iphone-mini-3">
              <img
                className="rectangle-7"
                alt="Rectangle"
                src="/img/rectangle-34-1.png"
              />
              <div className="overlap-group-5">
                <div className="frame-29">
                  <div className="frame-30">
                    <img className="image" alt="Image" src="/img/image-4.png" />
                    <div className="text-wrapper-32">Thanh toán với Momo</div>
                  </div>
                  <div className="text-wrapper-33">Tổng</div>
                </div>
                <div className="text-wrapper-34">160.000đ</div>
              </div>
              <div className="text-wrapper-35">Thanh toán</div>
              <div className="frame-31">
                <div className="frame-32">
                  <div className="frame-33">
                    <div className="text-wrapper-36">Vé tham dự</div>
                    <div className="text-wrapper-37">2</div>
                    <div className="text-wrapper-38">30.000 đ</div>
                  </div>
                  <div className="frame-33">
                    <div className="text-wrapper-36">Vé hạng nhất</div>
                    <div className="text-wrapper-37">1</div>
                    <div className="text-wrapper-38">100.000đ</div>
                  </div>
                </div>
                <div className="text-wrapper-39">Hạng vé</div>
                <div className="text-wrapper-40">Số lượng</div>
                <div className="text-wrapper-41">Giá</div>
              </div>
              <div className="frame-34">
                <div className="text-wrapper-42">Acoustic Night: Đến</div>
                <div className="frame-35">
                  <div className="frame-36">
                    <LiCalendar className="icon-instance-node-5" />
                  </div>
                  <p className="text-wrapper-43">
                    20:00 - 23:00 Chủ Nhật, 20/08/2023
                  </p>
                </div>
                <div className="frame-35">
                  <div className="frame-36">
                    <LiMapPin className="icon-instance-node-5" />
                  </div>
                  <p className="text-wrapper-44">
                    Cửa hàng 81 Cafe - 30 Nguyễn Hữu Cầu, P. Tân Định, Quận 1,
                    TP. Hồ Chí Minh
                  </p>
                </div>
              </div>
              <div className="text-wrapper-45">Đơn hàng</div>
              <LiChevronLeft2 className="li-chevron-left" color="#3A3555" />
              <div className="text-wrapper-46">Quay lại</div>
              <div className="bars-status-default-3">
                <div className="action-2">
                  <div className="time">9:41</div>
                </div>
                <div className="container-3">
                  <div className="battery-3">
                    <div className="rectangle-8" />
                  </div>
                  <img
                    className="combined-shape-3"
                    alt="Combined shape"
                    src="/img/combined-shape.svg"
                  />
                  <img className="wi-fi-3" alt="Wi fi" src="/img/wi-fi.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-38">
          <div className="text-wrapper-47">Tìm hiểu thêm</div>
        </div>
        <div className="frame-39">
          <div className="overlap-12">
            <div className="ellipse-7" />
            <img className="frame-40" alt="Frame" src="/img/frame-1.svg" />
            <img className="frame-41" alt="Frame" src="/img/frame-2.svg" />
          </div>
        </div>
        <div className="frame-42">
          <div className="text-wrapper-27">Pass vé?</div>
          <p className="text-wrapper-29">
            Chuyển nhượng vé ngay trên app, không cần email, không lo lừa đảo.
          </p>
          <div className="text-wrapper-48">An toàn</div>
        </div>
        <div className="frame-43">
          <div className="overlap-13">
            <div className="ellipse-5" />
            <div className="iphone-mini-4">
              <div className="overlap-group-6">
                <div className="overlap-14">
                  <div className="text-wrapper-49">Vé tham dự</div>
                  <p className="text-wrapper-50">
                    QR sẽ sẵn sàng trong 18:00:09
                  </p>
                  <IconQrCode className="icon-qr-code" />
                </div>
                <div className="frame-44">
                  <img
                    className="rectangle-9"
                    alt="Rectangle"
                    src="/img/rectangle-34.png"
                  />
                  <div className="text-wrapper-51">Acoustic Night: Đến</div>
                </div>
              </div>
            </div>
            <div className="frame-45">
              <LiSend1 className="li-send" />
              <div className="text-wrapper-52">Chuyển nhượng</div>
            </div>
          </div>
        </div>
        <div className="frame-46">
          <p className="text-wrapper-53">Tính năng đang được phát triển</p>
        </div>
      </div>
    </div>
  );
};
