/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiTicket1 = ({ className }) => {
  return (
    <svg
      className={`li-ticket-1 ${className}`}
      fill="none"
      height="11"
      viewBox="0 0 11 11"
      width="11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_448_1466)">
        <path
          className="path"
          clipRule="evenodd"
          d="M3.34455 2.98782C3.25377 2.97104 3.16005 2.99101 3.08399 3.04334C3.00794 3.09567 2.95579 3.17606 2.93902 3.26684L2.81251 3.9514C2.77758 4.14044 2.59601 4.26537 2.40698 4.23043C2.21794 4.1955 2.09301 4.01393 2.12795 3.8249L2.25445 3.14034C2.30478 2.868 2.46123 2.62681 2.68939 2.46983C2.91755 2.31285 3.19872 2.25293 3.47106 2.30325L8.26299 3.1888C8.53532 3.23913 8.77651 3.39558 8.93349 3.62374C9.09048 3.85189 9.1504 4.13307 9.10007 4.4054L8.97356 5.08996C8.93863 5.279 8.75706 5.40392 8.56803 5.36899C8.37899 5.33406 8.25407 5.15249 8.289 4.96346L8.41551 4.2789C8.43228 4.18812 8.41231 4.09439 8.35998 4.01834C8.30765 3.94229 8.22726 3.89014 8.13648 3.87336L3.34455 2.98782ZM2.15396 5.59956C2.343 5.63449 2.46792 5.81605 2.43299 6.00509L2.30648 6.68965C2.28971 6.78043 2.30968 6.87415 2.36201 6.95021C2.41434 7.02626 2.49473 7.07841 2.58551 7.09518L7.37744 7.98073C7.46822 7.99751 7.56194 7.97753 7.638 7.9252C7.71405 7.87288 7.7662 7.79248 7.78297 7.7017L7.90948 7.01714C7.94441 6.8281 8.12598 6.70318 8.31501 6.73811C8.50405 6.77305 8.62898 6.95461 8.59404 7.14365L8.46754 7.82821C8.41721 8.10055 8.26076 8.34173 8.0326 8.49872C7.80444 8.6557 7.52327 8.71562 7.25093 8.66529L2.459 7.77975C2.18667 7.72942 1.94548 7.57297 1.7885 7.34481C1.63151 7.11665 1.57159 6.83548 1.62192 6.56314L1.74843 5.87858C1.78336 5.68955 1.96493 5.56462 2.15396 5.59956Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M8.56806 5.36897C8.3865 5.33542 8.19905 5.37537 8.04694 5.48002C7.89484 5.58468 7.79054 5.74547 7.75699 5.92703C7.72344 6.10858 7.76338 6.29603 7.86804 6.44814C7.97269 6.60024 8.13349 6.70454 8.31504 6.73809C8.50408 6.77303 8.629 6.95459 8.59407 7.14363C8.55914 7.33266 8.37757 7.45759 8.18854 7.42266C7.82542 7.35555 7.50384 7.14695 7.29453 6.84274C7.08521 6.53853 7.00532 6.16363 7.07243 5.80052C7.13953 5.43741 7.34813 5.11582 7.65234 4.90651C7.95655 4.6972 8.33145 4.61731 8.69456 4.68441C8.8836 4.71934 9.00852 4.90091 8.97359 5.08994C8.93866 5.27898 8.75709 5.4039 8.56806 5.36897Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M2.12803 3.82489C2.16296 3.63585 2.34453 3.51092 2.53356 3.54586C2.89668 3.61296 3.21826 3.82156 3.42758 4.12577C3.63689 4.42998 3.71678 4.80488 3.64967 5.16799C3.58257 5.53111 3.37397 5.85269 3.06976 6.062C2.76555 6.27132 2.39065 6.35121 2.02754 6.2841C1.8385 6.24917 1.71358 6.06761 1.74851 5.87857C1.78345 5.68953 1.96501 5.56461 2.15405 5.59954C2.3356 5.63309 2.52305 5.59315 2.67516 5.48849C2.82726 5.38384 2.93156 5.22304 2.96511 5.04149C2.99866 4.85993 2.95872 4.67248 2.85406 4.52038C2.74941 4.36827 2.58862 4.26397 2.40706 4.23042C2.21802 4.19549 2.0931 4.01392 2.12803 3.82489Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M6.20935 2.80929C6.39838 2.84422 6.52331 3.02578 6.48837 3.21482L6.36187 3.89938C6.32693 4.08842 6.14537 4.21334 5.95633 4.17841C5.7673 4.14347 5.64237 3.96191 5.67731 3.77287L5.80381 3.08831C5.83875 2.89928 6.02031 2.77435 6.20935 2.80929Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M5.45031 6.91665C5.63935 6.95158 5.76427 7.13314 5.72934 7.32218L5.60283 8.00674C5.5679 8.19578 5.38634 8.3207 5.1973 8.28577C5.00826 8.25084 4.88334 8.06927 4.91827 7.88024L5.04478 7.19567C5.07971 7.00664 5.26128 6.88171 5.45031 6.91665Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M5.82983 4.86297C6.01887 4.8979 6.14379 5.07946 6.10886 5.2685L5.98235 5.95306C5.94742 6.1421 5.76585 6.26702 5.57682 6.23209C5.38778 6.19716 5.26286 6.01559 5.29779 5.82656L5.4243 5.14199C5.45923 4.95296 5.64079 4.82803 5.82983 4.86297Z"
          fill="#FF554C"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_448_1466">
          <rect
            className="rect"
            fill="white"
            height="8.35383"
            transform="translate(2.0127 0.617859) rotate(10.4701)"
            width="8.35383"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
