/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LiMapPin2 = ({ className }) => {
  return (
    <svg
      className={`li-map-pin-2 ${className}`}
      fill="none"
      height="9"
      viewBox="0 0 10 9"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_429_423)">
        <path
          className="path"
          clipRule="evenodd"
          d="M5.70684 1.42191C5.0709 1.30438 4.41432 1.4443 3.88154 1.81088C3.34876 2.17746 2.98343 2.74067 2.8659 3.37661C2.6943 4.30519 3.13196 5.31788 3.6652 6.13957C3.92689 6.54281 4.20109 6.88401 4.4102 7.12456C4.48802 7.21408 4.55653 7.28933 4.61165 7.3483C4.6842 7.31292 4.77509 7.26713 4.87976 7.21133C5.16102 7.0614 5.53906 6.84074 5.92758 6.55765C6.71927 5.98081 7.48995 5.19142 7.66155 4.26285C7.77907 3.6269 7.63915 2.97032 7.27257 2.43754C6.906 1.90476 6.34279 1.53943 5.70684 1.42191ZM4.54366 7.71619C4.33132 7.93486 4.33125 7.93479 4.33117 7.93472L4.33042 7.93399L4.32867 7.93228L4.32266 7.92636C4.31756 7.92132 4.31029 7.91409 4.301 7.90475C4.28244 7.88607 4.25582 7.85894 4.2224 7.82395C4.15559 7.75402 4.06145 7.65257 3.95012 7.52449C3.72786 7.26881 3.43499 6.90466 3.15384 6.47142C2.6014 5.62015 2.05032 4.43536 2.26645 3.26583C2.41335 2.47091 2.87002 1.76689 3.53599 1.30867C4.20197 0.850446 5.02269 0.675548 5.81762 0.82245C6.61255 0.969352 7.31656 1.42602 7.77479 2.092C8.23301 2.75797 8.40791 3.5787 8.26101 4.37362C8.04488 5.54315 7.10676 6.45274 6.28657 7.05035C5.86915 7.35449 5.46548 7.58992 5.16652 7.74928C5.01677 7.82911 4.89259 7.89022 4.8052 7.93165C4.76148 7.95238 4.72692 7.96821 4.70291 7.97902C4.6909 7.98443 4.68152 7.98858 4.67495 7.99147L4.66723 7.99484L4.66498 7.99581L4.66427 7.99612C4.66417 7.99616 4.66384 7.99631 4.54366 7.71619ZM4.54366 7.71619L4.66384 7.99631C4.55075 8.04482 4.41945 8.02044 4.33117 7.93472L4.54366 7.71619Z"
          fill="#3A3555"
          fillRule="evenodd"
        />
        <path
          className="path"
          clipRule="evenodd"
          d="M5.37451 3.22028C5.04344 3.15909 4.72546 3.37788 4.66427 3.70895C4.60309 4.04002 4.82188 4.35801 5.15295 4.41919C5.48402 4.48037 5.802 4.26158 5.86319 3.93051C5.92437 3.59944 5.70558 3.28146 5.37451 3.22028ZM4.06482 3.59817C4.18718 2.93603 4.82315 2.49846 5.48529 2.62082C6.14743 2.74318 6.58501 3.37915 6.46264 4.04129C6.34028 4.70343 5.70431 5.14101 5.04217 5.01864C4.38003 4.89628 3.94246 4.26031 4.06482 3.59817Z"
          fill="#3A3555"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_429_423">
          <rect
            className="rect"
            fill="white"
            height="7.31527"
            transform="translate(2.22089 0.157776) rotate(10.4701)"
            width="7.31527"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
